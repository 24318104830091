import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useActionData, useNavigate } from 'react-router-dom'
import AddIcon from '../../assets/AddIcon'
import ArrowDown from '../../assets/ArrowDown'
import CloseIcon from '../../assets/CloseIcon'
import EditIcon from '../../assets/EditIcon'
import TrashIcon from '../../assets/TrashIcon'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    ACCOUNTING_TYPE,
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'

import './Accounting.scss'
import DotsIcon from '../../assets/DotsIcon'
import DownloadIcon from '../../assets/DownloadIcon'
import InfoPrimary from '../../assets/infoPrimary'
import ChevronLeft from '../../assets/ChevronLeft.jsx'
import AddAccounting from './AddAccounting'
import {
    enableAccountingHelper,
    getAccountingHelper,
    getBalanceSheetStatementHelper,
    getProfitLossStatementHelper,
    getReceiptPaymentHelper,
    getVoucherDetailsHelper,
    getVouchersHelper,
    updateVoucherHelper
} from '../../helper/personal-details-helper'
import { convertToTitleCase, formatDate } from '../../common/utils/appUtils'

const Accounting = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [activeCardId, setActiveCardId] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.ACCOUNTING,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => props?.onBackClick()
    })
    const [accountingId, setAccountingId] = useState(true)
    const [isAddAccountingActive, setIsAddAccountingActive] = useState(false)
    const [isDateFieldVisible, setIsDateFieldVisible] = useState(false)
    const [enableAcconutingData, setEnableAccountingData] = useState({
        cash_in_hand: null,
        cash_in_account: null,
        paid_up_capital: null,
        retained_earning: null,
        loan: null,
        director_loan_to_business: null,
        invoice_factoring_provider: null,
        supplier_due: null,
        other_loans: null,
        pharma: null,
        non_pharma: null,
        advances_and_deposits: null,
        loan_to_director: null,
        loan_to_account_receivable: null,
        land_and_flat_store: null,
        electrical_items: null,
        vehicles: null,
        furniture_and_fixtures: null,
        term_deposits: null
    })
    const [shareholdersEquity, setShareholdersEquity] = useState(0)
    const [openingBalanceofLiabilities, setOpeningBalanceofLiabilities] =
        useState(0)
    const [openingBalanceofCurrentAsset, setOpeningBalanceofCurrentAsset] =
        useState(0)
    const [openingBalanceofFixedAsset, setOpeningBalanceofFixedAsset] =
        useState(0)
    const [acountingTypeActive, setAccountingTypeActive] = useState(
        ACCOUNTING_TYPE.VOUCHER_REPORT
    )
    const [isErrorFieldVisible, setIsErrorFieldVisible] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState(undefined)
    const [vouchersData, setVouchersData] = useState([])
    const [vouchersDetailsData, setVouchersDetailsData] = useState({})
    const [receiptPaymentData, setReceiptPaymentData] = useState({})
    const [profitLossData, setProfitLossData] = useState({})
    const [balanceSheetData, setBalanceSheetData] = useState({})
    const [editVoucher, setEditVoucher] = useState(false)
    const [voucherViewOnlyId, setVoucherViewOnlyId] = useState(undefined)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        getAccountingId()
    }, [])

    useEffect(() => {
        if (!isAddAccountingActive) {
            handleHeaderConfigUpdate(HEADER_CONST.ACCOUNTING, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        if (isAddAccountingActive) {
            handleHeaderConfigUpdate(HEADER_CONST.ACCOUNTING, () =>
                handleDrawerToggle()
            )
        }
    }, [isAddAccountingActive])

    useEffect(() => {
        let shareholdersEquity =
            (parseFloat(enableAcconutingData.paid_up_capital) || 0) +
            (parseFloat(enableAcconutingData.retained_earning) || 0)

        setShareholdersEquity(shareholdersEquity)
    }, [
        enableAcconutingData.paid_up_capital,
        enableAcconutingData.retained_earning
    ])

    useEffect(() => {
        let openingBalanceofLiabilities =
            (parseFloat(enableAcconutingData.loan) || 0) +
            (parseFloat(enableAcconutingData.director_loan_to_business) || 0) +
            (parseFloat(enableAcconutingData.invoice_factoring_provider) || 0) +
            (parseFloat(enableAcconutingData.supplier_due) || 0) +
            (parseFloat(enableAcconutingData.other_loans) || 0)

        setOpeningBalanceofLiabilities(openingBalanceofLiabilities)
    }, [
        enableAcconutingData.loan,
        enableAcconutingData.director_loan_to_business,
        enableAcconutingData.invoice_factoring_provider,
        enableAcconutingData.supplier_due,
        enableAcconutingData.other_loans
    ])

    useEffect(() => {
        let openingBalanceofCurrentAsset =
            (parseFloat(enableAcconutingData.cash_in_hand) || 0) +
            (parseFloat(enableAcconutingData.cash_in_account) || 0) +
            (parseFloat(enableAcconutingData.pharma) || 0) +
            (parseFloat(enableAcconutingData.non_pharma) || 0) +
            (parseFloat(enableAcconutingData.advances_and_deposits) || 0) +
            (parseFloat(enableAcconutingData.loan_to_director) || 0) +
            (parseFloat(enableAcconutingData.loan_to_account_receivable) || 0)

        setOpeningBalanceofCurrentAsset(openingBalanceofCurrentAsset)
    }, [
        enableAcconutingData.cash_in_hand,
        enableAcconutingData.cash_in_account,
        enableAcconutingData.pharma,
        enableAcconutingData.non_pharma,
        enableAcconutingData.advances_and_deposits,
        enableAcconutingData.loan_to_director,
        enableAcconutingData.loan_to_account_receivable
    ])

    useEffect(() => {
        let openingBalanceofFixedAsset =
            (parseFloat(enableAcconutingData.land_and_flat_store) || 0) +
            (parseFloat(enableAcconutingData.electrical_items) || 0) +
            (parseFloat(enableAcconutingData.vehicles) || 0) +
            (parseFloat(enableAcconutingData.furniture_and_fixtures) || 0) +
            (parseFloat(enableAcconutingData.term_deposits) || 0)

        setOpeningBalanceofFixedAsset(openingBalanceofFixedAsset)
    }, [
        enableAcconutingData.land_and_flat_store,
        enableAcconutingData.electrical_items,
        enableAcconutingData.vehicles,
        enableAcconutingData.furniture_and_fixtures,
        enableAcconutingData.term_deposits
    ])

    useEffect(() => {
        if (props.loginData.permissions) {
            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.OTHER
            )[0]

            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const getAccountingId = async () => {
        try {
            let accountingData = await getAccountingHelper()
            let aId = accountingData?.id
            setAccountingId(aId)
        } catch (err) {
            console.log('err Msg: ', err)
            setAccountingId(false)
        }
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const getHeaderButtonText = () => {
        let buttonText = 'Save'

        if (accountingId && !isAddAccountingActive) {
            // buttonText = 'Add'
            buttonText = <AddIcon />
        }

        return buttonText
    }

    const handleEnableAccounting = async () => {
        if (
            !enableAcconutingData.cash_in_hand ||
            !enableAcconutingData.cash_in_account
        ) {
            setIsErrorFieldVisible(true)
            return
        }

        try {
            let accountingId = await enableAccountingHelper(
                enableAcconutingData
            )

            setAccountingId(accountingId)
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
        }
    }

    const handleAddAccounting = () => {}

    const emptyData = () => {
        setBalanceSheetData({})
        setProfitLossData({})
        setReceiptPaymentData({})
        setVouchersData({})
    }

    const getProfitLossData = async () => {
        try {
            let pLossData = await getProfitLossStatementHelper(
                accountingId,
                startDate,
                endDate
            )

            setProfitLossData(pLossData)
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
        }
    }

    const getBalanceSheetData = async () => {
        try {
            let bSheetData = await getBalanceSheetStatementHelper(
                accountingId,
                startDate,
                endDate
            )

            setBalanceSheetData(bSheetData)
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
        }
    }

    const getReceiptPaymentData = async () => {
        try {
            let rData = await getReceiptPaymentHelper(
                accountingId,
                startDate,
                endDate
            )

            setReceiptPaymentData(rData)
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
        }
    }

    const getVouchersData = async () => {
        try {
            let vData = await getVouchersHelper(
                accountingId,
                startDate,
                endDate
            )

            setVouchersData(vData)
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
        }
    }

    const getVoucherDetails = async () => {
        try {
            let vDetailData = await getVoucherDetailsHelper(
                enableAcconutingData
            )

            setVouchersDetailsData(vDetailData)
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
        }
    }

    const handleButtonClick = () => {
        if (!accountingId) {
            handleEnableAccounting()
        } else if (!isAddAccountingActive) {
            setVoucherViewOnlyId(undefined)
            setEditVoucher(undefined)
            setIsAddAccountingActive(true)
        } else {
            handleAddAccounting()
        }
    }

    const handleSubmitClick = () => {
        console.log('startDate, endDate: ', startDate, endDate)
        if (!startDate || !endDate) {
            return
        }

        getVouchersData()
        getBalanceSheetData()
        getProfitLossData()
        getReceiptPaymentData()
        // switch (acountingTypeActive) {
        //     case ACCOUNTING_TYPE.VOUCHER_REPORT:

        //         break
        //     case ACCOUNTING_TYPE.RECEIPT_PAYMENT:

        //         break
        //     case ACCOUNTING_TYPE.BALANCE_SHEET:

        //         break
        //     case ACCOUNTING_TYPE.PROFIT_AND_LOSS:

        //         break
        //     default:
        //         break
        // }
    }

    const handleVoucherEdit = async id => {
        try {
            let vDetailData = await getVoucherDetailsHelper(id)
            // handleVoucherClick(id)
            setVoucherViewOnlyId(undefined)
            setVouchersDetailsData(vDetailData)
            setEditVoucher(true)
            setIsAddAccountingActive(true)
            setActiveCardId(undefined)
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
            setActiveCardId(undefined)
        }
    }

    const handleVoucherDelete = async id => {
        try {
            let reqData = {
                voucher_id: id,
                type: 'DELETE'
            }

            let vId = await updateVoucherHelper(reqData)
            setActiveCardId(undefined)
            getVouchersData()
        } catch (errMsg) {
            console.log('errMsg ', errMsg)
        }
    }

    const handleVoucherClick = id => {
        console.log('voucher click !!')
        setIsAddAccountingActive(true)
        setVoucherViewOnlyId(id)
    }

    const [isEditMode, setIsEditMode] = useState(false)
    const [isPresentData, setIsPresentData] = useState(true)

    const backToCurrentDataHandler = () => {
        setIsPresentData(true)
        setIsEditMode(false)
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {!isAddAccountingActive && (
                <div className="pageBodyRightPanel accounting">
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter">
                        {!isMobile && (
                            <div className="pageBodyRightPanelHeading">
                                Accounting
                            </div>
                        )}
                        <div className="chooseTimeline dFlex aiCenter">
                            <div className="formInline dFlex">
                                {accountingId && !isAddAccountingActive && (
                                    <>
                                        <div className="formGroup formFloating mb-0 flex1">
                                            <input
                                                type="date"
                                                className="formControl"
                                                id="email"
                                                placeholder="From"
                                                value={startDate}
                                                onChange={event => {
                                                    console.log(
                                                        event.target.value
                                                    )

                                                    emptyData()
                                                    setStartDate(
                                                        event.target.value
                                                    )
                                                }}
                                                required
                                            />
                                            <label for="address">From</label>
                                        </div>
                                        <div className="formGroup formFloating ml-4 mb-0 flex1">
                                            <input
                                                type="date"
                                                className="formControl"
                                                id="email"
                                                placeholder="To"
                                                value={endDate}
                                                onChange={event => {
                                                    console.log(
                                                        event.target.value
                                                    )
                                                    emptyData()
                                                    setEndDate(
                                                        event.target.value
                                                    )
                                                }}
                                                required
                                            />
                                            <label for="address">To</label>
                                        </div>
                                        <div
                                            className={`dFlex ${
                                                !isMobile
                                                    ? 'ml-4'
                                                    : 'mt-5 flexBreak50'
                                            }`}>
                                            <button
                                                className="btn btnPrimary flexBreak"
                                                onClick={() =>
                                                    handleSubmitClick()
                                                }>
                                                SUBMIT
                                            </button>
                                        </div>
                                    </>
                                )}
                                {permissions?.features?.[
                                    PERMISSION_FEATURE.ACCOUNTING
                                ] === PERMISSION_TYPE.MANAGE && (
                                    <div
                                        className={`dFlex ml-4 ${
                                            isMobile && 'mt-5'
                                        }`}>
                                        <button
                                            className="btn btnPrimary"
                                            onClick={() => handleButtonClick()}>
                                            {getHeaderButtonText()}
                                        </button>
                                    </div>
                                )}
                            </div>
                            {/* {!isMobile && (
                                <div className="pointer ml-4">
                                    <CloseIcon />
                                </div>
                            )} */}
                        </div>
                    </div>
                    {!accountingId && (
                        <div className="accountingMainScreen">
                            <div className="semiHeading mb-4">
                                Please declare following details to enable
                                accounting. The fields marked in '*' are
                                mandatory
                            </div>
                            <div className="">
                                <div className="formInline accountingForm">
                                    <div className="dFlex">
                                        <div className="accountingHalf">
                                            <div className="accountingFormSection">
                                                <div className="accountingFormSectionBar">
                                                    <div className="accountingFormSectionBarHeading">
                                                        Shareholders Equity
                                                    </div>
                                                    <div className="accountingFormSectionBarConclude">
                                                        {`BDT ${
                                                            shareholdersEquity ||
                                                            0
                                                        }`}
                                                    </div>
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Paid up Capital"
                                                        value={
                                                            enableAcconutingData.paid_up_capital
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    paid_up_capital:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Paid up Capital
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Paid up
                                                            Capital.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Retained Earning (Beginning Balance)"
                                                        value={
                                                            enableAcconutingData.retained_earning
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    retained_earning:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Retained Earning
                                                        (Beginning Balance)
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Retained Earning
                                                            (Beginning Balance).
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="accountingFormSection">
                                                <div className="accountingFormSectionBar">
                                                    <div className="accountingFormSectionBarHeading">
                                                        Opening Balance of
                                                        Liabilities
                                                    </div>
                                                    <div className="accountingFormSectionBarConclude">
                                                        {`BDT ${
                                                            openingBalanceofLiabilities ||
                                                            0
                                                        }`}
                                                    </div>
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Loan"
                                                        value={
                                                            enableAcconutingData.loan
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    loan: event
                                                                        .target
                                                                        .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Loan
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Loan.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Director's/Proprietor’s loan to Business"
                                                        value={
                                                            enableAcconutingData.director_loan_to_business
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    director_loan_to_business:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Director's/Proprietor’s
                                                        loan to Business
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Director's/Proprietor’s
                                                            loan to Business.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Invoice Factoring Provider"
                                                        value={
                                                            enableAcconutingData.invoice_factoring_provider
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    invoice_factoring_provider:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Invoice Factoring
                                                        Provider
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Invoice
                                                            Factoring Provider.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Suppliers Due/Account Payable"
                                                        requvalue={
                                                            enableAcconutingData.supplier_due
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    supplier_due:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                        ired
                                                    />
                                                    <label for="address">
                                                        Suppliers Due/Account
                                                        Payable
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Suppliers
                                                            Due/Account Payable.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Other Loans/Dues"
                                                        value={
                                                            enableAcconutingData.other_loans
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    other_loans:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Other Loans/Dues
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Other
                                                            Loans/Dues.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accountingHalf">
                                            <div className="accountingFormSection">
                                                <div className="accountingFormSectionBar">
                                                    <div className="accountingFormSectionBarHeading">
                                                        Opening Balance of
                                                        Current Asset
                                                    </div>
                                                    <div className="accountingFormSectionBarConclude">
                                                        {`BDT ${
                                                            openingBalanceofCurrentAsset ||
                                                            0
                                                        }`}
                                                    </div>
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Cash in Hand"
                                                        required
                                                        value={
                                                            enableAcconutingData.cash_in_hand
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    cash_in_hand:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Cash in Hand
                                                        <sup className="textPrimary">
                                                            *
                                                        </sup>
                                                    </label>
                                                    {isErrorFieldVisible &&
                                                        !enableAcconutingData.cash_in_hand && (
                                                            <div className="invalidFeedback">
                                                                Please enter CIH
                                                                (Cash in Hand).
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Cash in Bank"
                                                        required
                                                        value={
                                                            enableAcconutingData.cash_in_account
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    cash_in_account:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Cash in Bank
                                                        <sup className="textPrimary">
                                                            *
                                                        </sup>
                                                    </label>
                                                    {isErrorFieldVisible &&
                                                        !enableAcconutingData.cash_in_account && (
                                                            <div className="invalidFeedback">
                                                                Please enter
                                                                Cash in Bank.
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="accountingFormSectionBar insideHead">
                                                    <div className="accountingFormSectionBarHeading">
                                                        Inventory
                                                    </div>
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Pharma Products"
                                                        value={
                                                            enableAcconutingData.pharma
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    pharma: event
                                                                        .target
                                                                        .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Pharma Products
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Pharma
                                                            Products.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Non-Pharma Products"
                                                        value={
                                                            enableAcconutingData.non_pharma
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    non_pharma:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Non-Pharma Products
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Non-Pharma Products.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="accountingFormSectionBar insideHead">
                                                    <hr />
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Advances and Deposits"
                                                        value={
                                                            enableAcconutingData.advances_and_deposits
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    advances_and_deposits:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Advances and Deposits
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Advances and
                                                            Deposits.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Loan to Director/Proprietor"
                                                        value={
                                                            enableAcconutingData.loan_to_director
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    loan_to_director:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Loan to
                                                        Director/Proprietor
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Loan to
                                                            Director/Proprietor.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Loan to Account Receivable"
                                                        value={
                                                            enableAcconutingData.loan_to_account_receivable
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    loan_to_account_receivable:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Loan to Account
                                                        Receivable
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Loan to
                                                            Account Receivable.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="accountingFormSection">
                                                <div className="accountingFormSectionBar">
                                                    <div className="accountingFormSectionBarHeading">
                                                        Opening Balance of Fixed
                                                        Asset
                                                    </div>
                                                    <div className="accountingFormSectionBarConclude">
                                                        {`BDT ${
                                                            openingBalanceofFixedAsset ||
                                                            0
                                                        }`}
                                                    </div>
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Land and Flat/Store"
                                                        value={
                                                            enableAcconutingData.land_and_flat_store
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    land_and_flat_store:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Land and Flat/Store
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Land
                                                            and Flat/Store.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Electrical/Electronic Items"
                                                        value={
                                                            enableAcconutingData.electrical_items
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    electrical_items:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Electrical/Electronic
                                                        Items
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Electrical/Electronic
                                                            Items.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Vehicles"
                                                        value={
                                                            enableAcconutingData.vehicles
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    vehicles:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Vehicles
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Vehicles.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Furniture and Fixtures"
                                                        value={
                                                            enableAcconutingData.furniture_and_fixtures
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    furniture_and_fixtures:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Furniture and Fixtures
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter
                                                            Furniture and
                                                            Fixtures.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="formGroup formFloating formInputText">
                                                    <div className="preInputGroup backColor">
                                                        <div className="preInputText">
                                                            BDT
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="formControl"
                                                        id="email"
                                                        placeholder="Term Deposits"
                                                        value={
                                                            enableAcconutingData.term_deposits
                                                        }
                                                        onChange={event => {
                                                            setEnableAccountingData(
                                                                {
                                                                    ...enableAcconutingData,
                                                                    term_deposits:
                                                                        event
                                                                            .target
                                                                            .value
                                                                }
                                                            )
                                                        }}
                                                    />
                                                    <label for="address">
                                                        Term Deposits
                                                    </label>
                                                    {false && (
                                                        <div className="invalidFeedback">
                                                            Please enter Term
                                                            Deposits.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {accountingId && (
                        <div className="accountingBodyWrapper">
                            <ul className="tabs dFlex flexNowrap">
                                <li
                                    className={`tabItem ${
                                        acountingTypeActive ===
                                        ACCOUNTING_TYPE.RECEIPT_PAYMENT
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        setAccountingTypeActive(
                                            ACCOUNTING_TYPE.RECEIPT_PAYMENT
                                        )
                                    }>
                                    Receipt - Payment
                                </li>
                                <li
                                    className={`tabItem ${
                                        acountingTypeActive ===
                                        ACCOUNTING_TYPE.PROFIT_AND_LOSS
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        setAccountingTypeActive(
                                            ACCOUNTING_TYPE.PROFIT_AND_LOSS
                                        )
                                    }>
                                    Profit and Loss
                                </li>
                                <li
                                    className={`tabItem ${
                                        acountingTypeActive ===
                                        ACCOUNTING_TYPE.BALANCE_SHEET
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        setAccountingTypeActive(
                                            ACCOUNTING_TYPE.BALANCE_SHEET
                                        )
                                    }>
                                    Balance Sheet
                                </li>
                                <li
                                    className={`tabItem ${
                                        acountingTypeActive ===
                                        ACCOUNTING_TYPE.VOUCHER_REPORT
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        setAccountingTypeActive(
                                            ACCOUNTING_TYPE.VOUCHER_REPORT
                                        )
                                    }>
                                    Voucher Report
                                </li>
                            </ul>
                            <div className="accountingTabsWrapper">
                                {acountingTypeActive ===
                                    ACCOUNTING_TYPE.VOUCHER_REPORT &&
                                    vouchersData?.length > 0 && (
                                        <div className="tabContent mb-4">
                                            <div className="">
                                                <table className="table accounting">
                                                    <thead>
                                                        <tr>
                                                            <td>Date</td>
                                                            <td width="15%">
                                                                Voucher ID
                                                            </td>
                                                            <td width="30%">
                                                                Voucher Head
                                                            </td>
                                                            <td width="12%">
                                                                Created By
                                                            </td>
                                                            <td width="12%">
                                                                Dr/Payment
                                                            </td>
                                                            <td width="12%">
                                                                Cr/Received
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {vouchersData?.map(
                                                            voucher => {
                                                                return (
                                                                    <tr
                                                                        onClick={() =>
                                                                            handleVoucherClick(
                                                                                voucher.id
                                                                            )
                                                                        }>
                                                                        <td className="textPrimary">
                                                                            {formatDate(
                                                                                voucher.created_at
                                                                            )}
                                                                        </td>
                                                                        <td className="textPrimary">
                                                                            {
                                                                                voucher.voucher_id
                                                                            }
                                                                        </td>
                                                                        <td className="textPrimary">
                                                                            {convertToTitleCase(
                                                                                voucher.category
                                                                            )}
                                                                        </td>
                                                                        <td className="textPrimary">
                                                                            {
                                                                                voucher.created_by
                                                                            }
                                                                        </td>
                                                                        <td className="textPrimary">
                                                                            {`BDT ${
                                                                                voucher.total_debit ||
                                                                                voucher.total_credit
                                                                            }`}
                                                                        </td>
                                                                        <td className="textPrimary">
                                                                            {`BDT ${
                                                                                voucher.total_credit ||
                                                                                voucher.total_debit
                                                                            }`}
                                                                        </td>
                                                                        <td>
                                                                            <div className="cardHeaderRight">
                                                                                {activeCardId ===
                                                                                voucher.id ? (
                                                                                    <span
                                                                                        onClick={e => {
                                                                                            e.stopPropagation()
                                                                                            setActiveCardId(
                                                                                                undefined
                                                                                            )
                                                                                        }}>
                                                                                        <CloseIcon />
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        onClick={e => {
                                                                                            e.stopPropagation()
                                                                                            setActiveCardId(
                                                                                                voucher.id
                                                                                            )
                                                                                        }}>
                                                                                        <DotsIcon />
                                                                                    </span>
                                                                                )}
                                                                                {activeCardId ===
                                                                                    voucher.id && (
                                                                                    <ul
                                                                                        className={`cardActionsWrapper ${
                                                                                            activeCardId ===
                                                                                            voucher.id
                                                                                                ? 'show'
                                                                                                : 'hide'
                                                                                        }`}>
                                                                                        <li
                                                                                            className="actionItem"
                                                                                            onClick={e => {
                                                                                                e.stopPropagation()
                                                                                                handleVoucherEdit(
                                                                                                    voucher.id
                                                                                                )
                                                                                            }}>
                                                                                            Edit
                                                                                        </li>
                                                                                        <li
                                                                                            className="actionItem"
                                                                                            onClick={e => {
                                                                                                e.stopPropagation()
                                                                                                handleVoucherDelete(
                                                                                                    voucher?.id
                                                                                                )
                                                                                            }}>
                                                                                            Delete
                                                                                        </li>
                                                                                    </ul>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )}

                                                        {/* <tr>
                                                            <td className="textPrimary">
                                                                24/10/2023
                                                            </td>
                                                            <td className="textPrimary">
                                                                2024/Feb/02-01
                                                            </td>
                                                            <td className="textPrimary">
                                                                Fixed Asset
                                                            </td>
                                                            <td className="textPrimary">
                                                                BDT 12,000
                                                            </td>
                                                            <td className="textPrimary">
                                                                BDT 200
                                                            </td>
                                                            <td className="textPrimary">
                                                                BDT 200
                                                            </td>
                                                            <td>
                                                                <div className="cardHeaderRight">
                                                                    {activeCardId ? (
                                                                        <span
                                                                            onClick={() =>
                                                                                setActiveCardId(
                                                                                    false
                                                                                )
                                                                            }>
                                                                            <CloseIcon />
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            onClick={() =>
                                                                                setActiveCardId(
                                                                                    true
                                                                                )
                                                                            }>
                                                                            <DotsIcon />
                                                                        </span>
                                                                    )}
                                                                    {activeCardId && (
                                                                        <ul
                                                                            className={`cardActionsWrapper ${
                                                                                activeCardId
                                                                                    ? 'show'
                                                                                    : 'hide'
                                                                            }`}>
                                                                            <li className="actionItem">
                                                                                Edit
                                                                            </li>
                                                                            <li className="actionItem">
                                                                                Delete
                                                                            </li>
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                {acountingTypeActive ===
                                    ACCOUNTING_TYPE.RECEIPT_PAYMENT &&
                                    receiptPaymentData.hasOwnProperty(
                                        'total_payments'
                                    ) && (
                                        <div className="tabContent">
                                            <div className="dFlex">
                                                <div className="accountingHalf">
                                                    <div className="tableHeading">
                                                        Receipt
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/** opening Balance */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Opening
                                                                        Balance
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.opening_balance}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Cash in
                                                                        A/C
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.opening_cash_in_account}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Cash-in-Hand
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.opening_cash_in_hand}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Liabilities */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Liabilities
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_liabilities_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Loan
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.loan_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Director/Proprietor's
                                                                        Loan to
                                                                        business
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.director_loan_to_business_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Invoice
                                                                        Factoring
                                                                        Provider
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.invoice_factoring_provider_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Supplier's
                                                                        Due /
                                                                        Account
                                                                        Payable
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.supplier_due_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Other
                                                                        Loans/Due
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.other_loans_due_credit}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Current Assets */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Current
                                                                        Assets
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_current_assets_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Advances
                                                                        /
                                                                        Deposits
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.advances_deposits_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Loan to
                                                                        Director/Proprietor
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.loan_to_director_credit}`}
                                                                    </td>
                                                                    {/*  */}
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Account
                                                                        Receivables
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.account_receivables_credit}`}
                                                                    </td>
                                                                    {/*  */}
                                                                </tr>
                                                            </>
                                                            {/** Sales */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Sales
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_sales}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Pharma
                                                                        Product
                                                                        Sales
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.actual_price_of_pharma_products_sold}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Non-
                                                                        Pharma
                                                                        Product
                                                                        Sales
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.actual_price_of_non_pharma_products_sold}`}
                                                                    </td>
                                                                    {/*  */}
                                                                </tr>
                                                            </>
                                                            {/** Fixed Assets */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Fixed
                                                                        Assets
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_fixed_assets_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Land &
                                                                        Flat/Store
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.land_and_flat_store_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Electrical/Electronic
                                                                        Items
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.electrical_items_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Vehicles
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.vehicles_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Furniture
                                                                        &
                                                                        Fixtures
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.furniture_and_fixtures_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Term
                                                                        Deposits
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.term_deposits_credit}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** shareholder's equity */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Shareholder's
                                                                        Equity
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.shareholders_equity_credit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Paid up
                                                                        capital
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.paid_up_capital}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Total
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${receiptPaymentData.total_credit}`}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div className="accountingHalf">
                                                    <div className="tableHeading">
                                                        Payment
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/** Current Assets */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Current
                                                                        Assets
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_current_assets_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Advances
                                                                        /
                                                                        Deposits
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.advances_deposits_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Loan to
                                                                        Director/Proprietor
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.loan_to_director_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Account
                                                                        Receivables
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.account_receivables_debit}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Fixed Assets */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Fixed
                                                                        Assets
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_fixed_assets_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Land &
                                                                        Flat/Store
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.land_and_flat_store_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Electrical/Electronic
                                                                        Items
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.electrical_items_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Vehicles
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.vehicles_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Furniture
                                                                        &
                                                                        Fixtures
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.furniture_and_fixtures_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Term
                                                                        Deposits
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.term_deposits_debit}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** shareholder's equity */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Shareholder's
                                                                        Equity
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.shareholders_equity_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Withdrawals
                                                                        /
                                                                        Dividend
                                                                        to
                                                                        Shareholders
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.dividend_to_shareholders}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Liabilities */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Liabilities
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_liabilities_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Loan
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.loan_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Director/Proprietor's
                                                                        Loan to
                                                                        business
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.director_loan_to_business_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Invoice
                                                                        Factoring
                                                                        Provider
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.invoice_factoring_provider_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Supplier's
                                                                        Due /
                                                                        Account
                                                                        Payable
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.supplier_due_debit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Other
                                                                        Loans/Due
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.other_loans_due_debit}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Purchase/ Direct Expense */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Purchase/
                                                                        Direct
                                                                        Expense
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.total_purchase_amount}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Pharma
                                                                        Product
                                                                        Purchase
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.total_pharma_purchase}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Non-Pharma
                                                                        Product
                                                                        Purchase
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.total_non_pharma_purchase}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Indirect expenses */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Indirect
                                                                        expenses
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${receiptPaymentData.indirect_expenses}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="textPrimary">
                                                                        Salary &
                                                                        Allowances
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.total_salary_expenses}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Owner's
                                                                        Allowance
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.owners_allowance}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Staff
                                                                        Salary
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.staff_salary}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Stuff
                                                                        Allowance
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.stuff_allowance}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Festival
                                                                        Bonus
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.festival_bonus}`}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="textPrimary">
                                                                        General
                                                                        Expenses
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.total_general_expenses}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Shop/Store
                                                                        Rent
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.shop_store_rent}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Platform
                                                                        Expense
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.platform_expense}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Delivery
                                                                        Expense
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.delivery_expense}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Income
                                                                        TAX paid
                                                                        to govt.
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.income_tax_paid_to_govt}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        VAT paid
                                                                        to govt.
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.vat_paid_to_govt}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Electricity
                                                                        Bill
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.electricity_bill}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Water
                                                                        Bill
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.electricity_bill}`}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Connectivity
                                                                        Bill
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.connectivity_bill}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        BCDS
                                                                        monthly
                                                                        Fee
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.bcds_monthly_fee}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Entertainment
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.entertainment}`}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Travel /
                                                                        Conveyance
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.travel_conveyance}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Stationary
                                                                        /
                                                                        Computer
                                                                        Accessories
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.stationary_computer_accessories}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Licencing
                                                                        &
                                                                        renewal
                                                                        fee
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.licencing_renewal_fee}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Expired
                                                                        Product
                                                                        Return
                                                                        Loss
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.expired_product_return_loss}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Damage/lost
                                                                        Loss
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.damage_lost_loss}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Depreciation
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.depreciation}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        POS
                                                                        machine
                                                                        charge
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.pos_machine_charge}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Interest
                                                                        Expense
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.interest_expense}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Bank
                                                                        charges
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.bank_charges}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Legal
                                                                        Charge &
                                                                        Fees
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.legal_charge_fees}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Audit
                                                                        and Tax
                                                                        Consultancy
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.audit_and_tax_consultancy}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Marketing/Advertising
                                                                        Expenses
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.marketing_advertising_expenses}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Miscellaneous
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${receiptPaymentData.miscellaneous}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Total
                                                                    Payments
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${receiptPaymentData.total_payments}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Closing
                                                                    Balance
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${receiptPaymentData.closing_balance}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Total
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${receiptPaymentData.total_debit}`}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {acountingTypeActive ===
                                    ACCOUNTING_TYPE.PROFIT_AND_LOSS &&
                                    profitLossData?.hasOwnProperty(
                                        'debit_total'
                                    ) && (
                                        <div className="tabContent">
                                            <div className="dFlex">
                                                <div className="accountingHalf">
                                                    <div className="tableHeading">
                                                        Debit/Payments
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Opening
                                                                    Stock
                                                                    (Inventory)
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.total_opening_stock}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.opening_pharma_stock}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Non-Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.opening_non_pharma_stock}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Purchase
                                                                    Account
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.total_purchase_amount}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.total_pharma_purchase}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Non-Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.total_non_pharma_purchase}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Cost of
                                                                    Produts Sold
                                                                    (COPS)
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.cost_of_products_sold}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.cost_of_pharma_products_sold}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Non-Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.cost_of_non_pharma_products_sold}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Indirect
                                                                    Expenses
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.indirect_expenses}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Salary &
                                                                    Allowances
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.salary_allowances}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    General
                                                                    Expenses
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.general_expenses}`}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Total
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${profitLossData.debit_total}`}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div className="accountingHalf">
                                                    <div className="tableHeading">
                                                        Credits/Sales
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Sales
                                                                    Account
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.sales_account}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.actual_price_of_pharma_products_sold}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Non-Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.actual_price_of_non_pharma_products_sold}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Closing
                                                                    Stock
                                                                    (Inventory)
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.total_closing_stock}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.closing_pharma_stock}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Non-Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.closing_non_pharma_stock}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Gross Profit
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.gross_profit}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.pharma_product_profit}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Non-Pharma
                                                                    Product
                                                                </td>
                                                                <td
                                                                    className="textRight"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.non_pharma_product_profit}`}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold primary">
                                                                    Net Profit /
                                                                    Loss
                                                                </td>
                                                                <td
                                                                    className="textBold primary"
                                                                    colSpan="2">
                                                                    {`BDT ${profitLossData.net_profit_and_loss}`}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Total
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${profitLossData.credit_total}`}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {acountingTypeActive ===
                                    ACCOUNTING_TYPE.BALANCE_SHEET && (
                                    // balanceSheetData?.total_shareholders_equity &&
                                    <div className="tabContent">
                                        <div className="previousBarWrapper dFlex flexNowrap aiCenter">
                                            <div
                                                className={`p-4 dFlex aiCenter cursorPointer chevron ${
                                                    isPresentData && 'move'
                                                }`}
                                                onClick={
                                                    backToCurrentDataHandler
                                                }>
                                                <ChevronLeft />
                                            </div>
                                            <div className="previousBar dFlex aiCenter width100 jcSpaceBetween flexNowrap">
                                                <div className="dFlex flexNowrap aiCenter">
                                                    {!isPresentData && (
                                                        <InfoPrimary />
                                                    )}
                                                    <div
                                                        className={`textPrimary textBold ml-2 ${
                                                            isPresentData &&
                                                            'presentData'
                                                        }`}
                                                        onClick={() =>
                                                            setIsPresentData(
                                                                false
                                                            )
                                                        }>
                                                        View Previous Year
                                                        Balance Sheet
                                                    </div>
                                                </div>
                                                <button
                                                    className={`btn btnPrimary ml-4 ${
                                                        isPresentData && 'hide'
                                                    }`}
                                                    onClick={() =>
                                                        setIsEditMode(
                                                            !isEditMode
                                                        )
                                                    }>
                                                    {isEditMode
                                                        ? 'SAVE'
                                                        : 'EDIT'}
                                                </button>
                                            </div>
                                        </div>
                                        {isPresentData && (
                                            <div className="dFlex">
                                                <div className="accountingHalf">
                                                    <div className="tableHeading">
                                                        Liabilities & Owner’s
                                                        Equity
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/** Shareholders’ Equity */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Shareholders’
                                                                        Equity
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${balanceSheetData.total_shareholders_equity}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="textPrimary">
                                                                        Paid up
                                                                        Capital
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.paid_up_capital}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="textPrimary">
                                                                        Retained
                                                                        Earning
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.retained_earning_total}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Retained
                                                                        Earning
                                                                        (Beginning
                                                                        Balance)
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.retained_earning}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Current
                                                                        year
                                                                        profit
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.current_year_profit}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Withdrawals
                                                                        /
                                                                        Dividend
                                                                        to
                                                                        Shareholders
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.dividend_to_shareholders}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Liabilities */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Liabilities
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${balanceSheetData.liabilities_total}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Loan
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.loan}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Director/Proprietor's
                                                                        Loan to
                                                                        business
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.director_loan_to_business}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Invoice
                                                                        Factoring
                                                                        Provider
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.invoice_factoring_provider}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Supplier's
                                                                        Due/Account
                                                                        Payable
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.supplier_due}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Other
                                                                        Loans/Due
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.other_loans_due}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Total
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${balanceSheetData.total_liabilities}`}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div className="accountingHalf">
                                                    <div className="tableHeading">
                                                        Assets
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/** Current Assets */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Current
                                                                        Assets
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${balanceSheetData.total_current_assets}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Cash in
                                                                        Hand
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.cash_in_hand}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Cash in
                                                                        Bank
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.cash_in_account}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Inventory
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.total_inventory}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Pharma
                                                                        products
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.pharma_products}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Non-Pharma
                                                                        products
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.non_pharma_products}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Advances
                                                                        /
                                                                        Deposits
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.advances_deposits}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Loan to
                                                                        Director/Proprietor
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.loan_to_director}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Account
                                                                        Receivables
                                                                    </td>
                                                                    <td
                                                                        className="textRight textPrimary"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.account_receivables}`}
                                                                    </td>
                                                                </tr>
                                                            </>

                                                            {/** Fixed Assets */}
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="textBold primary">
                                                                        Fixed
                                                                        Assets
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT ${balanceSheetData.total_fixed_assets}`}
                                                                    </td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td>
                                                                        Shareholder's
                                                                        Equity
                                                                    </td>
                                                                    <td className="textRight" rowSpan='2'>
                                                                        {`BDT ${receiptPaymentData.indirect_expenses}`}
                                                                    </td>
                                                                    
                                                                </tr> */}
                                                                <tr>
                                                                    <td>
                                                                        Land &
                                                                        Flat/Store
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.land_and_flat_store}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Electrical/Electronic
                                                                        Items
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.electrical_items}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Vehicles
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.vehicles}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Furniture
                                                                        &
                                                                        Fixtures
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.furniture_and_fixtures}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Term
                                                                        Deposits
                                                                    </td>
                                                                    <td
                                                                        className="textRight"
                                                                        colSpan="2">
                                                                        {`BDT ${balanceSheetData.term_deposits}`}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td
                                                                    colSpan="2"
                                                                    className="textBold">
                                                                    Total
                                                                </td>
                                                                <td className="textRight textBold">
                                                                    {`BDT ${balanceSheetData.total_assets}`}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                        {!isPresentData && (
                                            <div className="dFlex">
                                                <div className="accountingHalf">
                                                    <div className="tableHeading">
                                                        Liabilities & Owner's
                                                        Equity
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/** Shareholders’ Equity */}
                                                            <>
                                                                <tr>
                                                                    <td className="textBold primary">
                                                                        Shareholders’
                                                                        Equity
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {`BDT 0`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="textPrimary">
                                                                        Paid up
                                                                        Capital
                                                                    </td>
                                                                    <td className="textRight textPrimary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Retained
                                                                        Earning
                                                                        (Beginning
                                                                        Balance)
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            {/** Liabilities */}
                                                            <>
                                                                <tr>
                                                                    <td className="textBold primary">
                                                                        Opening
                                                                        Balance
                                                                        of
                                                                        Liabilities
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Loan
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Director/Proprietor's
                                                                        Loan to
                                                                        business
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Invoice
                                                                        Factoring
                                                                        Provider
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Supplier's
                                                                        Due/Account
                                                                        Payable
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Other
                                                                        Loans/Due
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div
                                                    className={`accountingHalf ${
                                                        isMobile && 'mt-5'
                                                    }`}>
                                                    <div className="tableHeading">
                                                        Assets
                                                    </div>
                                                    <table className="table accounting">
                                                        <thead>
                                                            <tr>
                                                                <td
                                                                    width="95%"
                                                                    className="textBold">
                                                                    Description
                                                                </td>
                                                                <td
                                                                    width="5%"
                                                                    className="textRight textBold">
                                                                    Total
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/** Current Assets */}
                                                            <>
                                                                <tr>
                                                                    <td className="textBold primary">
                                                                        Opening
                                                                        Balance
                                                                        of
                                                                        Current
                                                                        Asset
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Cash in
                                                                        Hand
                                                                    </td>
                                                                    <td className="textRight textPrimary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Cash in
                                                                        Bank
                                                                    </td>
                                                                    <td className="textRight textPrimary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Inventory
                                                                    </td>
                                                                    <td className="textRight textPrimary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Pharma
                                                                        products
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Non-Pharma
                                                                        products
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Advances
                                                                        &
                                                                        Deposits
                                                                    </td>
                                                                    <td className="textRight textPrimary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Loan to
                                                                        Director/Proprietor
                                                                    </td>
                                                                    <td className="textRight textPrimary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=" textPrimary">
                                                                        Account
                                                                        Receivables
                                                                    </td>
                                                                    <td className="textRight textPrimary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </>

                                                            {/** Fixed Assets */}
                                                            <>
                                                                <tr>
                                                                    <td className="textBold primary">
                                                                        Opening
                                                                        Balance
                                                                        of Fixed
                                                                        Assets
                                                                    </td>
                                                                    <td className="textBold primary">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Land &
                                                                        Flat/Store
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Electrical/Electronic
                                                                        Items
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Vehicles
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Furniture
                                                                        &
                                                                        Fixtures
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Term
                                                                        Deposits
                                                                    </td>
                                                                    <td className="textRight">
                                                                        {isEditMode ? (
                                                                            <div className="formGroup">
                                                                                <input
                                                                                    type="text"
                                                                                    className="formControl form-sm"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            `BDT 0`
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {isAddAccountingActive && (
                <AddAccounting
                    accountingId={accountingId}
                    onCancel={() => {
                        getVouchersData()
                        setIsAddAccountingActive(false)
                    }}
                    isEditVoucher={editVoucher}
                    vouchersDetailsData={vouchersDetailsData}
                    voucherViewOnlyId={voucherViewOnlyId}
                />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounting)
