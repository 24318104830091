import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import { PAGE_CONST } from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import { redirect } from '../../common/utils/redirectionUtils'
import MobileHeader from '../Common/Header/MobileHeader'

import './Reports.scss'
import CloseIcon from '../../assets/CloseIcon'
import ProductReceivingBlack from '../../assets/ProductReceivingBlack'
import AddIcon from '../../assets/AddIcon'
import SearchIcon from '../../assets/SearchIcon'
import FilterSettings from '../../assets/FilterSettings'
import DownloadIcon from '../../assets/DownloadIcon'
import {
    downloadReportExcelHelper,
    downloadReportPdfHelper,
    getReportHelper
} from '../../helper/home-helper'
import Select from 'react-select'
import {
    downloadReportExcelAPI,
    downloadReportPdfAPI
} from '../../adapter/adapter'
import { convertToTitleCase } from '../../common/utils/appUtils'

const Reports = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()

    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.REPORTS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })

    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [reportType, setReportType] = useState(undefined)
    const [subType, setSubType] = useState(undefined)
    const [selectedSupplier, setSelectedSupplier] = useState(undefined)
    const [selectedProductType, setSelectedProductType] = useState(undefined)
    const [selectedGenericName, setSelectedGenericName] = useState(undefined)
    const [reportOrderData, setReportOrderData] = useState(undefined)
    const [reportInvoicePurchaseData, setReportInvoicePurchaseData] =
        useState(undefined)
    const [
        filterReportInvoicePurchaseData,
        setFilterReportInvoicePurchaseData
    ] = useState(undefined)
    const [filterReportOrderData, setFilterReportOrderData] =
        useState(undefined)
    const [reportSupplierProductData, setReportSupplierProductData] =
        useState(undefined)
    const [
        filteredReportSupplierProductData,
        setFilteredReportSupplierProductData
    ] = useState(undefined)
    const [dailySalesSummaryData, setDailySalesSummaryData] =
        useState(undefined)
    const [filteredDailySalesSummaryData, setFilteredDailySalesSummaryData] =
        useState(undefined)
    const [supplierWiseSalesData, setSupplierWiseSalesData] =
        useState(undefined)
    const [filteredSupplierWiseSalesData, setFilteredSupplierWiseSalesData] =
        useState(undefined)
    const [supplierWiseStockPositionData, setSupplierWiseStockPositionData] =
        useState(undefined)
    const [
        filteredSupplierWiseStockPositionData,
        setFilteredSupplierWiseStockPositionData
    ] = useState(undefined)
    const [
        supplierWiseProductMovementData,
        setSupplierWiseProductMovementData
    ] = useState(undefined)
    const [
        filteredSupplierWiseProductMovementData,
        setFilteredSupplierWiseProductMovementData
    ] = useState(undefined)
    const [toggle, setToggle] = useState(false)

    const setDataEmpty = () => {
        setReportOrderData(undefined)
        setFilterReportOrderData(undefined)
        setReportSupplierProductData(undefined)
        setFilteredReportSupplierProductData(undefined)
        setDailySalesSummaryData(undefined)
        setFilteredDailySalesSummaryData(undefined)
        setSupplierWiseSalesData(undefined)
        setFilteredSupplierWiseSalesData(undefined)
        setSupplierWiseStockPositionData(undefined)
        setFilteredSupplierWiseStockPositionData(undefined)
        setSupplierWiseProductMovementData(undefined)
        setFilteredSupplierWiseProductMovementData(undefined)
        setSelectedGenericName(undefined)
        setSelectedProductType(undefined)
        setSelectedSupplier(undefined)
    }
    const handleSubmit = async () => {
        if (!startDate || !endDate || !reportType || !subType) return

        let data = {
            startDate: startDate,
            endDate: endDate,
            reportType: reportType?.name,
            subType: subType?.name
        }

        try {
            console.log('data for report helper: ', data)
            setDataEmpty()

            let res = await getReportHelper(data)

            if (
                reportType?.name === 'PURCHASE' &&
                subType?.name === 'SUPPLIER_AND_PRODUCT'
            ) {
                setReportSupplierProductData(res)
                setFilteredReportSupplierProductData(res?.data || [])
            } else if (
                reportType?.name === 'PURCHASE' &&
                subType?.name === 'ORDER_SUBMIT'
            ) {
                setReportOrderData(res)
                setFilterReportOrderData(res?.data)
            } else if (
                reportType?.name === 'PURCHASE' &&
                subType?.name === 'INVOICE_WISE_PURCHASE_REPORT'
            ) {
                setReportInvoicePurchaseData(res)
                setFilterReportInvoicePurchaseData(res?.data)
            } else if (
                reportType?.name === 'SALES' &&
                subType?.name === 'DAILY_SALES_SUMMARY'
            ) {
                setDailySalesSummaryData(res)
                setFilteredDailySalesSummaryData(res?.data)
            } else if (
                reportType?.name === 'SALES' &&
                subType?.name === 'SUPPLIER_WISE'
            ) {
                setSupplierWiseSalesData(res)
                setFilteredSupplierWiseSalesData(res?.data)
            } else if (
                reportType?.name === 'INVENTORY' &&
                subType?.name === 'SUPPLIER_WISE_STOCK_POSITION'
            ) {
                setSupplierWiseStockPositionData(res)
                setFilteredSupplierWiseStockPositionData(res?.data)
            } else if (
                reportType?.name === 'INVENTORY' &&
                subType?.name === 'SUPPLIER_WISE_PRODUCT_MOVEMENT'
            ) {
                setSupplierWiseProductMovementData(res)
                setFilteredSupplierWiseProductMovementData(res?.data)
            }

            console.log('reports response:: ', res)
        } catch (err) {
            console.log('err in get report: ', err)
        }
        // api call
    }

    const filterSupplierReportData = option => {
        setSelectedSupplier(option)
        if (!option?.name) {
            setFilteredReportSupplierProductData(
                reportSupplierProductData?.data
            )
            setFilterReportOrderData(reportOrderData?.data)
            setFilteredSupplierWiseSalesData(supplierWiseSalesData?.data)
            setFilteredSupplierWiseStockPositionData(
                supplierWiseStockPositionData?.data
            )
            setFilteredSupplierWiseProductMovementData(
                supplierWiseProductMovementData?.data
            )
            return
        }

        setSelectedProductType(undefined)
        setSelectedGenericName(undefined)

        let data
        if (reportOrderData) {
            data =
                reportOrderData?.data?.filter(
                    obj => obj.supplier === option.name
                ) || []

            setFilterReportOrderData(data)
        } else if (reportSupplierProductData) {
            data =
                reportSupplierProductData?.data?.filter(
                    obj => obj.supplier_details === option.name
                ) || []

            setFilteredReportSupplierProductData(data)
        } else if (supplierWiseSalesData) {
            data =
                supplierWiseSalesData?.data?.filter(
                    obj => obj.supplier_name === option.name
                ) || []

            setFilteredSupplierWiseSalesData(data)
        } else if (supplierWiseStockPositionData) {
            data =
                supplierWiseStockPositionData?.data?.filter(
                    obj => obj.supplier_name === option.name
                ) || []

            setFilteredSupplierWiseStockPositionData(data)
        } else if (supplierWiseProductMovementData) {
            data =
                supplierWiseProductMovementData?.data?.filter(
                    obj => obj.supplier_name === option.name
                ) || []

            setFilteredSupplierWiseProductMovementData(data)
        }
    }

    const filterSalesManReportData = option => {
        if (!option?.name) {
            setFilteredDailySalesSummaryData(dailySalesSummaryData?.data)
            return
        }

        let data =
            dailySalesSummaryData?.data?.filter(
                obj => obj.sales_man === option.name
            ) || []

        setFilteredDailySalesSummaryData(data)
    }

    const filterProductTypeReportData = option => {
        setSelectedProductType(option)
        if (!option?.name) {
            setFilteredSupplierWiseSalesData(supplierWiseSalesData?.data)
            setFilteredSupplierWiseStockPositionData(
                supplierWiseStockPositionData?.data
            )
            setFilteredSupplierWiseProductMovementData(
                supplierWiseProductMovementData?.data
            )
            return
        }

        setSelectedGenericName(undefined)
        setSelectedSupplier(undefined)

        let data
        if (supplierWiseSalesData) {
            data =
                supplierWiseSalesData?.data?.filter(
                    obj => obj.product_type === option.name
                ) || []
            setFilteredSupplierWiseSalesData(data)
        } else if (supplierWiseStockPositionData) {
            data =
                supplierWiseStockPositionData?.data?.filter(
                    obj => obj.product_type === option.name
                ) || []
            setFilteredSupplierWiseStockPositionData(data)
        } else if (supplierWiseProductMovementData) {
            data =
                supplierWiseProductMovementData?.data?.filter(
                    obj => obj.product_type === option.name
                ) || []
            setFilteredSupplierWiseProductMovementData(data)
        }
    }

    const filterGenericNameReportData = option => {
        setSelectedGenericName(option)
        if (!option?.name) {
            setFilteredSupplierWiseSalesData(supplierWiseSalesData?.data)
            return
        }

        setSelectedProductType(undefined)
        setSelectedSupplier(undefined)

        let data =
            supplierWiseSalesData?.data?.filter(
                obj => obj.generic_name === option.name
            ) || []

        setFilteredSupplierWiseSalesData(data)
    }

    const getSupplierOptions = () => {
        let data =
            reportSupplierProductData?.data ||
            reportOrderData?.data ||
            supplierWiseSalesData?.data ||
            supplierWiseStockPositionData?.data ||
            supplierWiseProductMovementData?.data ||
            []
        const supplierOptionsArray = []
        let supplierSet = new Set()

        data.map(obj => {
            supplierSet.add(
                obj.supplier_details || obj.supplier || obj.supplier_name || []
            )
        })

        supplierSet.forEach(option =>
            supplierOptionsArray.push({
                name: option,
                label: option
            })
        )

        return supplierOptionsArray
    }

    const getProductTypeOptions = () => {
        let data =
            supplierWiseSalesData?.data ||
            supplierWiseStockPositionData?.data ||
            supplierWiseProductMovementData?.data ||
            []
        const productTypeOptionsArray = []
        let productTypeSet = new Set()

        data.map(obj => {
            productTypeSet.add(obj.product_type)
        })

        productTypeSet.forEach(option =>
            productTypeOptionsArray.push({
                name: option,
                label: option
            })
        )

        return productTypeOptionsArray
    }

    const getGenericNameOptions = () => {
        let data = supplierWiseSalesData?.data || []
        const genericNameOptionsArray = []
        let genericNameSet = new Set()

        data.map(obj => {
            genericNameSet.add(obj.generic_name)
        })

        genericNameSet.forEach(option =>
            genericNameOptionsArray.push({
                name: option,
                label: option
            })
        )

        return genericNameOptionsArray
    }

    const getSalesManOptions = () => {
        let data = dailySalesSummaryData?.data
        const salesManOptionsArray = []
        let salesManSet = new Set()

        data.map(obj => {
            salesManSet.add(obj.sales_man)
        })

        salesManSet.forEach(option =>
            salesManOptionsArray.push({
                name: option,
                label: option
            })
        )

        return salesManOptionsArray
    }

    const getSubTypeOptions = () => {
        if (!reportType) {
            return []
        }

        let options
        switch (reportType?.name) {
            case 'PURCHASE':
                options = [
                    {
                        name: 'SUPPLIER_AND_PRODUCT',
                        label: 'Supplier and Product'
                    },
                    {
                        name: 'ORDER_SUBMIT',
                        label: 'Order Submit'
                    },
                    {
                        name: 'INVOICE_WISE_PURCHASE_REPORT',
                        label: 'Invoice Wise Purchase Report'
                    }
                ]
                break
            case 'SALES':
                options = [
                    {
                        name: 'DAILY_SALES_SUMMARY',
                        label: 'Daily Sales Summary'
                    },
                    {
                        name: 'SUPPLIER_WISE',
                        label: 'Supplier wise'
                    }
                ]
                break
            case 'INVENTORY':
                options = [
                    {
                        name: 'SUPPLIER_WISE_STOCK_POSITION',
                        label: 'Supplier wise Stock Position'
                    },
                    {
                        name: 'SUPPLIER_WISE_PRODUCT_MOVEMENT',
                        label: 'Supplier wise Product Movement'
                    }
                ]
                break
            default:
                options = []
                break
        }

        return options
    }

    const handleDownloadClick = async type => {
        console.log('type in download:: ', type)
        if (!startDate || !endDate || !reportType || !subType) return

        let data = {
            startDate: startDate,
            endDate: endDate,
            reportType: reportType?.name,
            subType: subType?.name
        }

        try {
            console.log('data for report download helper: ', data)
            // setReportSupplierProductData(undefined)

            let res
            if (type === 'PDF') res = await downloadReportPdfHelper(data)
            else if (type === 'CSV') res = await downloadReportExcelHelper(data)

            console.log('reports download response:: ', res)
        } catch (err) {
            console.log('err in download report: ', err)
        }

        setToggle(false)
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            <div className="pageBodyRightPanel reportsWrapper">
                <div className="addProductBtnMobile dFlex aiCenter fixed">
                    {/* <button className="animated zoomIn btn btnPrimary ml-2">
                        <FilterSettings />
                    </button> */}
                    <div className="buttonOptionsWrapper">
                        <button
                            className="animated zoomIn btn btnPrimary ml-2"
                            onClick={() => setToggle(!toggle)}>
                            <DownloadIcon />
                        </button>
                        {toggle && (
                            <ul className="buttonOptions">
                                <li
                                    className="option"
                                    onClick={() => handleDownloadClick('PDF')}>
                                    PDF
                                </li>
                                <li
                                    className="option"
                                    onClick={() => handleDownloadClick('CSV')}>
                                    CSV
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter mb-4">
                    <div className="pageBodyRightPanelHeading">Reports</div>
                    <div className="addProductBtn dFlex aiCenter">
                        <div className="pointer ml-4">
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <form>
                    <div className="dFlex">
                        <div className="formGroup formFloating mb-0 flex1">
                            <input
                                type="date"
                                className="formControl"
                                id="email"
                                placeholder="To"
                                onChange={event =>
                                    setStartDate(event.target.value)
                                }
                                required
                            />
                            <label for="address">To</label>
                        </div>
                        <div className="formGroup formFloating mb-0 ml-4 flex1">
                            <input
                                type="date"
                                className="formControl"
                                id="email"
                                placeholder="From"
                                onChange={event =>
                                    setEndDate(event.target.value)
                                }
                                required
                            />
                            <label for="address">From</label>
                        </div>
                        <div
                            className={`formGroup formFloating mb-0 flex1 ${
                                !isMobile ? 'ml-4' : 'mt-5 flexBreak50'
                            }`}>
                            <Select
                                className="formSelect reactSelect"
                                id="floatingSelect"
                                getOptionLabel={option => option.label}
                                placeholder="Select Report Type"
                                styles={{
                                    control: base => ({
                                        border: 0,
                                        display: 'flex',
                                        paddingTop: '20px'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused
                                            ? 'rgb(222, 235, 255)'
                                            : state.isSelected
                                            ? '#FFF'
                                            : 'inherit',
                                        color: '#212529',
                                        '&:hover': {
                                            backgroundColor: state.isSelected
                                                ? '#DEEBFF'
                                                : 'rgb(222, 235, 255)'
                                        }
                                    })
                                }}
                                value={reportType}
                                options={[
                                    {
                                        name: 'PURCHASE',
                                        label: 'Purchase'
                                    },
                                    {
                                        name: 'SALES',
                                        label: 'Sales'
                                    },
                                    {
                                        name: 'INVENTORY',
                                        label: 'Inventory'
                                    }
                                ]}
                                onChange={option => {
                                    setSubType('')
                                    setReportType(option)
                                }}
                                isClearable
                            />

                            {/* <select className="formSelect" id="floatingSelect">
                                <option value="1">Purchase</option>
                                <option value="2">Sale</option>
                            </select> */}
                            <label for="floatingSelect">Report Type</label>
                            {false && (
                                <div className="invalidFeedback">
                                    Please choose Report Type.
                                </div>
                            )}
                        </div>
                        <div
                            className={`formGroup formFloating mb-0 flex1 ml-4 ${
                                !isMobile ? '' : 'mt-5 flexBreak50'
                            }`}>
                            <Select
                                className="formSelect reactSelect"
                                id="floatingSelect"
                                getOptionLabel={option => option.label}
                                placeholder="Select Sub Type"
                                styles={{
                                    control: base => ({
                                        border: 0,
                                        display: 'flex',
                                        paddingTop: '20px'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused
                                            ? 'rgb(222, 235, 255)'
                                            : state.isSelected
                                            ? '#FFF'
                                            : 'inherit',
                                        color: '#212529',
                                        '&:hover': {
                                            backgroundColor: state.isSelected
                                                ? '#DEEBFF'
                                                : 'rgb(222, 235, 255)'
                                        }
                                    }),
                                    menuPortal: base => ({
                                        ...base,
                                        zIndex: 100000
                                    })
                                }}
                                value={subType}
                                options={getSubTypeOptions()}
                                onChange={option => setSubType(option)}
                                isClearable
                            />

                            {/* <select className="formSelect" id="floatingSelect">
                                <option value="1">Order Wise</option>
                                <option value="2">Supplier Wise</option>
                                <option value="2">Product Wise</option>
                            </select> */}
                            <label for="floatingSelect">Sub Type</label>
                            {false && (
                                <div className="invalidFeedback">
                                    Please choose Sub Type.
                                </div>
                            )}
                        </div>
                        <div
                            className={`actionButton dFlex ${
                                !isMobile ? 'ml-4' : 'mt-5 flexBreak'
                            }`}>
                            <button
                                className="btn btnPrimary dFlex aiCenter block jcContentCenter"
                                onClick={event => {
                                    event?.preventDefault()
                                    handleSubmit()
                                }}>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
                <div className="reportsWrapperBody">
                    {/* <div className="consolidateStrip dFlex jcSpaceBetween aiCenter mt-5">
                        <div className="consolidateDataIcon dFlex">
                            <ProductReceivingBlack />
                        </div>
                        <div className="consolidateDataText">
                            Purchase (12/10/2023 - 12/10/2023){' '}
                            <small>BDT</small>
                        </div>
                        <div className="consolidateData">38,500</div>
                    </div> */}
                    {filteredReportSupplierProductData && (
                        <div className="mt-5 tableResponsive">
                            <table className="table reports">
                                <thead>
                                    <tr>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Select Supplier"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={selectedSupplier || ''}
                                                options={getSupplierOptions()}
                                                onChange={option =>
                                                    filterSupplierReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td>Product Details</td>
                                        <td>Product Type</td>
                                        <td>Batch No.</td>
                                        <td>Expiry Date </td>
                                        <td>Category</td>
                                        <td>Rcv Qty</td>
                                        <td>Bonus Qty</td>
                                        <td>Total Qty</td>
                                        <td>CPU (BDT)</td>
                                        <td>Total Cost (BDT)</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredReportSupplierProductData?.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.supplier_details}</td>
                                                <td>{item.product_details}</td>
                                                <td>{item.product_type}</td>
                                                <td>{item.batch_number}</td>
                                                <td>{item.expiry_date}</td>
                                                <td>{item.category}</td>
                                                <td>{item.rec_qty}</td>
                                                <td>{item.bonus_qty}</td>
                                                <td>{item.total_qty}</td>
                                                <td>{item.cpu}</td>
                                                <td>{item.total_cost}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="6">Total</td>
                                        <td>
                                            {filteredReportSupplierProductData.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.rec_qty || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {filteredReportSupplierProductData.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.bonus_qty || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {filteredReportSupplierProductData.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.total_qty || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {/* {
                                                    reportSupplierProductData
                                                        ?.total?.overall_cpu
                                                } */}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredReportSupplierProductData.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_cost ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    )}
                    {filterReportOrderData && (
                        <div className="mt-5 tableResponsive">
                            <table className="table reports">
                                <thead>
                                    <tr>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Select Supplier"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={selectedSupplier || ''}
                                                options={getSupplierOptions()}
                                                onChange={option =>
                                                    filterSupplierReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td>Product Details</td>
                                        <td>Category</td>
                                        <td>Order Qty</td>
                                        <td>CPU (BDT)</td>
                                        <td>Total Cost (BDT)</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterReportOrderData?.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.supplier}</td>
                                                <td>{item.product_details}</td>
                                                <td>{item.category}</td>
                                                <td>{item.order_qty}</td>
                                                <td>{item.cpu}</td>
                                                <td>{item.total_cost}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="3">Total</td>
                                        <td>
                                            {filterReportOrderData.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.order_qty || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {/* {
                                                    reportOrderData?.total
                                                        ?.overall_cpu
                                                } */}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filterReportOrderData.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_cost ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    )}

                    {filterReportInvoicePurchaseData && (
                        <div className="mt-5 tableResponsive">
                            <table className="table reports">
                                <thead>
                                    <tr>
                                        <td>Date</td>
                                        <td>Invoice No.</td>
                                        <td>Order Book Invoice No.</td>
                                        <td>Supplier's Name</td>
                                        <td>Amount (BDT)</td>
                                        <td>VAT Amount (+)</td>
                                        <td>Trade Discount (-)</td>
                                        <td>Total (BDT)</td>
                                        <td>Payment Mode</td>
                                        <td>Note</td>
                                        <td>Received By</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterReportInvoicePurchaseData?.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.created_at}</td>
                                                <td>{item.invoice_number}</td>
                                                <td>
                                                    {
                                                        item.order_book_invoice_number
                                                    }
                                                </td>
                                                <td>{item.supplier_name}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.vat}</td>
                                                <td>{item.trade_discount}</td>
                                                <td>{item.total_amount}</td>
                                                <td>
                                                    {convertToTitleCase(
                                                        item.payment_mode
                                                    )}
                                                </td>
                                                <td>{item.notes}</td>
                                                <td>{item.received_by}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="4">Total</td>
                                        <td>
                                            {
                                                reportInvoicePurchaseData?.total
                                                    ?.overall_amount
                                            }
                                            {/* {filterReportInvoicePurchaseData.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.overall_amount ||
                                                            0)
                                                    )
                                                },
                                                0
                                            )} */}
                                        </td>
                                        <td>
                                            {
                                                reportInvoicePurchaseData?.total
                                                    ?.overall_vat
                                            }
                                        </td>
                                        <td>
                                            {
                                                reportInvoicePurchaseData?.total
                                                    ?.overall_trade_discount
                                            }
                                        </td>
                                        <td>
                                            {
                                                reportInvoicePurchaseData?.total
                                                    ?.overall_total_amount
                                            }
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {/* <td>
                                            {parseFloat(
                                                filterReportOrderData.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_cost ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td> */}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    )}

                    {filteredDailySalesSummaryData && (
                        <div className="mt-5 tableResponsive">
                            <table className="table reports">
                                <thead>
                                    <tr>
                                        <td>Invoice No.</td>
                                        <td>Date & Time</td>
                                        <td>MRP</td>
                                        <td>Discount (BDT) (-)</td>
                                        <td>VAT (+)</td>
                                        <td>Ex/Ret Amt (-)</td>
                                        <td>Net Amount</td>
                                        <td>Sales Type</td>
                                        <td>Cash Sale</td>
                                        <td>Card Sale</td>
                                        <td>Online Sale</td>
                                        <td>Due Sale</td>
                                        <td>Notes</td>
                                        <td>Total CPU</td>
                                        <td>Profit Amt.</td>
                                        <td>GP%</td>
                                        {/* <td>Sales-man</td> */}
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Sales-man"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={selectedSupplier || ''}
                                                options={getSalesManOptions()}
                                                onChange={option =>
                                                    filterSalesManReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDailySalesSummaryData?.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.invoice_number}</td>
                                                <td>{item.date_and_time}</td>
                                                <td>{item.mrp}</td>
                                                <td>{item.discount}</td>
                                                <td>{item.vat}</td>
                                                <td>{item.ex_ret_amount}</td>
                                                <td>{item.net_amount}</td>
                                                <td>{item.sales_type}</td>
                                                <td>{item.cash_sale}</td>
                                                <td>{item.card_sale}</td>
                                                <td>{item.online_sale}</td>
                                                <td>{item.due_sales}</td>
                                                <td>{item.notes}</td>
                                                <td>{item.total_cpu}</td>
                                                <td>{item.profit_amount}</td>
                                                <td>{item.gp}</td>
                                                <td>{item.sales_man}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="2">Total</td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.mrp || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.discount || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.vat || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.ex_ret_amount ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.net_amount ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td></td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.cash_sale ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.card_sale ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.online_sale ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.due_sales ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>-</td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_cpu ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredDailySalesSummaryData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.profit_amount ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {(() => {
                                                const totalProfit =
                                                    filteredDailySalesSummaryData?.reduce(
                                                        (total, item) =>
                                                            total +
                                                            (item.profit_amount ||
                                                                0),
                                                        0
                                                    )

                                                const totalCPU =
                                                    filteredDailySalesSummaryData?.reduce(
                                                        (total, item) =>
                                                            total +
                                                            (item.total_cpu ||
                                                                0),
                                                        0
                                                    )

                                                const percentage = totalCPU
                                                    ? (totalProfit / totalCPU) *
                                                      100
                                                    : 0

                                                return parseFloat(
                                                    percentage.toFixed(2)
                                                )
                                            })()}
                                        </td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    )}
                    {filteredSupplierWiseSalesData && (
                        <div className="mt-5 tableResponsive">
                            <table className="table reports">
                                <thead>
                                    <tr>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Select Supplier"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={selectedSupplier || ''}
                                                options={getSupplierOptions()}
                                                onChange={option =>
                                                    filterSupplierReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td>Product Details</td>
                                        <td>Sold Qty</td>
                                        <td>CPU</td>
                                        <td>MRP</td>
                                        <td>Discount</td>
                                        <td>VAT (+)</td>
                                        <td>Sub Total</td>
                                        <td>Ex/Ret </td>
                                        {/* <td>Void</td> */}
                                        <td>Net Amount</td>
                                        <td>Total Profit</td>
                                        <td>GP</td>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Product Type"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={
                                                    selectedProductType || ''
                                                }
                                                options={getProductTypeOptions()}
                                                onChange={option =>
                                                    filterProductTypeReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Generic Name"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={
                                                    selectedGenericName || ''
                                                }
                                                options={getGenericNameOptions()}
                                                onChange={option =>
                                                    filterGenericNameReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredSupplierWiseSalesData?.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.supplier_name}</td>
                                                <td>{item.product_details}</td>
                                                <td>{item.sold_qty}</td>
                                                <td>{item.cpu}</td>
                                                <td>{item.mrp}</td>
                                                <td>{item.discount}</td>
                                                <td>{item.vat}</td>
                                                <td>{item.sub_total}</td>
                                                <td>{item.ex_ret}</td>
                                                {/* <td>{item.void}</td> */}
                                                <td>{item.net_amount}</td>
                                                <td>{item.total_profit}</td>
                                                <td>{item.gp}</td>
                                                <td>{item.product_type}</td>
                                                <td>{item.generic_name}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="2">Total</td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.sold_qty || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.cpu || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.mrp || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.discount || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.vat || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.sub_total ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.ex_ret || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        {/* <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.void || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td> */}
                                        {/* <td>-</td> */}
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.net_amount ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2) || 0}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseSalesData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_profit ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2) || 0}
                                        </td>
                                        <td>
                                            {(() => {
                                                const totalProfit =
                                                    filteredSupplierWiseSalesData?.reduce(
                                                        (total, item) =>
                                                            total +
                                                            (item.total_profit ||
                                                                0),
                                                        0
                                                    )

                                                const totalCPU =
                                                    filteredSupplierWiseSalesData?.reduce(
                                                        (total, item) =>
                                                            total +
                                                            (item.cpu || 0),
                                                        0
                                                    )

                                                const percentage = totalCPU
                                                    ? (totalProfit / totalCPU) *
                                                      100
                                                    : 0

                                                return parseFloat(
                                                    percentage.toFixed(2)
                                                )
                                            })()}
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    )}
                    {filteredSupplierWiseStockPositionData && (
                        <div className="mt-5 tableResponsive">
                            <table className="table reports">
                                <thead>
                                    <tr>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Select Supplier"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={selectedSupplier || ''}
                                                options={getSupplierOptions()}
                                                onChange={option =>
                                                    filterSupplierReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td>Product Details</td>
                                        <td>Category</td>

                                        <td>Batch number</td>
                                        <td>Expiry date</td>
                                        <td>Damage/Loss</td>
                                        <td>Exch/ Return</td>
                                        <td>Balance Qty</td>
                                        <td>CPU </td>
                                        <td>Total Cost</td>
                                        <td>RPU</td>
                                        <td>Total Sell Value</td>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Product Type"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={
                                                    selectedProductType || ''
                                                }
                                                options={getProductTypeOptions()}
                                                onChange={option =>
                                                    filterProductTypeReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td>Origin</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredSupplierWiseStockPositionData?.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.supplier_name}</td>
                                                <td>{item.product_details}</td>
                                                <td>{item.category}</td>
                                                <td>{item.batch_number}</td>
                                                <td>{item.expiry_date}</td>
                                                <td>{item.damage_loss}</td>
                                                <td>{item.exch_return}</td>
                                                <td>{item.balance_qty}</td>
                                                <td>{item.cpu}</td>
                                                <td>{item.total_cost}</td>
                                                <td>{item.rpu}</td>
                                                <td>{item.total_sell_value}</td>
                                                <td>{item.product_type}</td>
                                                <td>{item.origin}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="5">Total</td>
                                        <td>
                                            {filteredSupplierWiseStockPositionData?.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.damage_loss || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {filteredSupplierWiseStockPositionData?.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.exch_return || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {filteredSupplierWiseStockPositionData?.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.balance_qty || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td></td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseStockPositionData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_cost ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td></td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseStockPositionData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_sell_value ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    )}
                    {filteredSupplierWiseProductMovementData && (
                        <div className="mt-5 tableResponsive">
                            <table className="table reports">
                                <thead>
                                    <tr>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Select Supplier"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={selectedSupplier || ''}
                                                options={getSupplierOptions()}
                                                onChange={option =>
                                                    filterSupplierReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td>Product Details</td>
                                        <td>Category</td>
                                        <td>Total Received Qty</td>
                                        <td>Total Sold Qty</td>
                                        <td>Balance Qty</td>
                                        {/* <td>CPU </td> */}
                                        <td>Total Cost</td>
                                        {/* <td>RPU</td> */}
                                        <td>Total Sell Value</td>
                                        <td
                                            style={{
                                                // zIndex: 100,
                                                position: 'relative'
                                            }}>
                                            <Select
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                getOptionLabel={option =>
                                                    option?.label
                                                }
                                                menuPortalTarget={document.body}
                                                placeholder="Product Type"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    }),
                                                    menuPortal: base => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                value={
                                                    selectedProductType || ''
                                                }
                                                options={getProductTypeOptions()}
                                                onChange={option =>
                                                    filterProductTypeReportData(
                                                        option
                                                    )
                                                }
                                                isClearable
                                                isMenuOpen={true}
                                            />
                                        </td>
                                        <td>Origin</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredSupplierWiseProductMovementData?.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td>{item.supplier_name}</td>
                                                <td>{item.product_details}</td>
                                                <td>{item.category}</td>
                                                <td>
                                                    {item.total_received_qty}
                                                </td>

                                                <td>{item.total_sold_qty}</td>
                                                <td>{item.balance_qty}</td>
                                                <td>{item.total_cost}</td>
                                                {/* <td>{item.rpu}</td> */}
                                                <td>{item.total_sell_value}</td>
                                                <td>{item.product_type}</td>
                                                <td>{item.origin}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="3">Total</td>
                                        <td>
                                            {filteredSupplierWiseProductMovementData?.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.total_received_qty ||
                                                            0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {filteredSupplierWiseProductMovementData?.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.total_sold_qty ||
                                                            0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {filteredSupplierWiseProductMovementData?.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        (item.balance_qty || 0)
                                                    )
                                                },
                                                0
                                            )}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseProductMovementData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_cost ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(
                                                filteredSupplierWiseProductMovementData?.reduce(
                                                    (total, item) => {
                                                        return (
                                                            total +
                                                            (item.total_sell_value ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            ).toFixed(2)}
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    )}
                    {/* <div className="mt-5">
                        <ul className="tabs dFlex">
                            <li className="tabItem active">Pharmaceutical</li>
                            <li className="tabItem">Non Pharmaceutical</li>
                        </ul>
                        <div className="tabContent dFlex mt-5">
                            <div className="tableResponsive">
                                <table className="table reports">
                                    <thead>
                                        <tr>
                                            <td>Medicine </td>
                                            <td>Type</td>
                                            <td>Generic Name</td>
                                            <td>Supplier Name</td>
                                            <td>Dose</td>
                                            <td>Quantity</td>
                                            <td>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportSupplierProductData?.data?.map(
                                            (item, index) => (
                                                <tr key={index}>
                                                    <td>{item.Medicine}</td>
                                                    <td>{item.Type}</td>
                                                    <td>{item.GenericName}</td>
                                                    <td>{item.Supplier}</td>
                                                    <td>{item.Dose}</td>
                                                    <td>{item.Quantity}</td>
                                                    <td>{item.Amount}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
