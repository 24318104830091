import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import './Sales.scss'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import LocationIcon from '../../assets/LocationIcon'
import {
    formatDate,
    formatTime,
    isReactNativeWebView
} from '../../common/utils/appUtils'
import {
    PAYMENT_MODE_OPTIONS,
    PRODUCT_DETAIL_FIELDS,
    REACT_NATIVE_MESSAGE_TYPE
} from '../../common/constant/page-constant'
import DownloadIcon from '../../assets/DownloadIcon'
import html2canvas from 'html2canvas'
import CallIcon from '../../assets/CallIcon'
import PhoneIcon from '../../assets/PhoneIcon'

function ViewSales({ open, handleDrawerToggle, data }) {
    const navigate = useNavigate()
    const ref = useRef(null)
    const [isDownloading, setIsDownloading] = useState(false)

    const getVatAmount = () => {
        if (data.vat && data.amount) {
            let vat =
                (data.vat / 100) *
                (data.amount - getDiscountAmount() - getTradeDiscountAmount())
            return parseFloat(vat).toFixed(2)
        }

        return 0
    }

    const getDiscountAmount = () => {
        if (data.discount && data.amount)
            return (data.discount / 100) * data.amount

        return 0
    }

    const getTradeDiscountAmount = () => {
        if (data.trade_discount && data.amount)
            return (data.trade_discount / 100) * data.amount

        return 0
    }

    const captureScreenshot = async () => {
        try {
            setIsDownloading(true)

            await new Promise(resolve => setTimeout(resolve, 1000))

            var canvasPromise = html2canvas(ref.current, {
                useCORS: true,
                allowTaint: false,
                letterRendering: true,
                // crossOrigin: 'anonymous',
                scale: window.devicePixelRatio * 3
            })

            canvasPromise.then(canvas => {
                var paddedCanvas = document.createElement('canvas')
                var context = paddedCanvas.getContext('2d')
                var padding = 30
                paddedCanvas.width = canvas.width + 2 * padding
                paddedCanvas.height = canvas.height + 2 * padding
                context.fillStyle = '#ffffff' // background color
                context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height)
                context.drawImage(canvas, padding, padding)

                // Convert the modified canvas to a data URL
                var dataURL = paddedCanvas.toDataURL('image/png')

                if (isReactNativeWebView()) {
                    let message = JSON.stringify({
                        type: REACT_NATIVE_MESSAGE_TYPE.IMAGE_DOWNLOAD,
                        data: dataURL
                    })
                    window.ReactNativeWebView.postMessage(message)
                } else {
                    var a = document.createElement('a')
                    a.innerHTML = 'DOWNLOAD'
                    a.target = '_blank'
                    a.href = dataURL
                    // Set the download attribute of the link
                    a.download = 'sales.png' // filename
                    document.body.appendChild(a)

                    a.click()
                    document.body.removeChild(a)
                }
            })
        } catch (errMsg) {
            console.log('errMsg: ', errMsg)
        } finally {
            setIsDownloading(false)
        }
    }

    return (
        <SideDrawer
            handleDrawerToggle={handleDrawerToggle}
            open={open}
            drawerHeading="Sales">
            <div className="viewProductWrap">
                {isDownloading && (
                    <div className="loader">
                        <p>
                            Please wait while we are generating your invoice...
                        </p>
                    </div>
                )}
                <div ref={ref}>
                    <div className="viewProductHeader">
                        <div className="contactDetailsRow dFlex aiCenter">
                            {data?.branch_details?.logo && (
                                <div className="contactDetailsRowLogo">
                                    <img
                                        src={`${
                                            data?.branch_details?.logo
                                        }?v=${new Date().getTime()}`}
                                        alt="logo"
                                        crossOrigin="anonymous"
                                    />
                                </div>
                            )}
                            <div>
                                <div className="productSupplierName">
                                    {data?.branch_details?.name}
                                </div>
                                <div className="productSupplierName number no-border">
                                    {data?.branch_details?.mobile_no}
                                </div>
                            </div>
                        </div>
                        <div className="productSupplierAddress dFlex aiCenter">
                            <LocationIcon className="icon" />{' '}
                            {data?.branch_details?.address}{' '}
                        </div>
                    </div>
                    <div className="viewProductHeader">
                        <div className="headerRow dFlex jcSpaceBetween aiCenter">
                            <div className="ProductUniqueTag">
                                {data.invoice_number}{' '}
                                <div className="cardCrown">
                                    {data.type === 'IN_STORE'
                                        ? 'In Store'
                                        : 'Online'}
                                </div>
                            </div>
                            <div className="headerRowRight">
                                {formatDate(data.created_at)}{' '}
                                {formatTime(data.created_at)}
                            </div>
                        </div>
                        {/* <ul className='moreDetailsRow dFlex'>
                        <li className='moreDetails'>Batch No. : 34567</li>
                        <li className='moreDetails'>Expiry Date: 25 Jan 2024</li>
                    </ul> */}
                    </div>
                    <div className="viewProductHeader">
                        <div className="contactDetailsRow dFlex jcSpaceBetween">
                            <div className="productSupplierName">
                                {data.type === 'IN_STORE'
                                    ? data?.customer?.name
                                    : 'DG ePharma'}
                            </div>

                            <div className="productSupplierName">
                                {data.type !== 'IN_STORE' ? (
                                    <a
                                        href={`tel:+${data?.customer?.mobile_no}`}>
                                        <CallIcon />
                                    </a>
                                ) : (
                                    data?.customer?.mobile_no
                                )}
                            </div>
                        </div>
                        {data.type === 'IN_STORE' && (
                            <div className="productSupplierAddress dFlex aiCenter">
                                <LocationIcon className="icon" />{' '}
                                {data?.customer?.shipping_address}{' '}
                            </div>
                        )}
                    </div>
                    <div className="productDescriptionWrap">
                        <table className="invoiceTable">
                            <thead>
                                <tr>
                                    <td width="60px">Sl. No.</td>
                                    <td width="25%">Product Name</td>
                                    {!isDownloading && <td>Batch no.</td>}
                                    {!isDownloading && <td>Expiry</td>}
                                    <td>Qty</td>
                                    <td>Rate/Ut</td>
                                    <td>Amt</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.product_details &&
                                    data.product_details.map(
                                        (product, index) => {
                                            console.log('product: ', product)
                                            const amount =
                                                product?.[
                                                    PRODUCT_DETAIL_FIELDS
                                                        .QUANTITY
                                                ] *
                                                product?.[
                                                    PRODUCT_DETAIL_FIELDS
                                                        .RATE_PER_UNIT
                                                ]

                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td className="tdWrap">
                                                        {product?.[
                                                            PRODUCT_DETAIL_FIELDS
                                                                .PRODUCT_NAME
                                                        ] +
                                                            ' ' +
                                                            (product?.[
                                                                PRODUCT_DETAIL_FIELDS
                                                                    .DOSE
                                                            ] || '')}
                                                    </td>
                                                    {!isDownloading && (
                                                        <td>
                                                            {
                                                                product?.[
                                                                    PRODUCT_DETAIL_FIELDS
                                                                        .INVENTORY
                                                                ]?.[
                                                                    PRODUCT_DETAIL_FIELDS
                                                                        .BATCH_NUMBER
                                                                ]
                                                            }
                                                        </td>
                                                    )}
                                                    {!isDownloading && (
                                                        <td>
                                                            {formatDate(
                                                                product
                                                                    ?.inventory
                                                                    ?.expiry_date
                                                            )}
                                                        </td>
                                                    )}
                                                    <td>
                                                        {
                                                            product?.[
                                                                PRODUCT_DETAIL_FIELDS
                                                                    .QUANTITY
                                                            ]
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            product?.[
                                                                PRODUCT_DETAIL_FIELDS
                                                                    .RATE_PER_UNIT
                                                            ]
                                                        }
                                                    </td>

                                                    <td>{amount}</td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>
                        <table
                            className="table productPrice"
                            cellPadding={2}
                            cellSpacing={2}>
                            <thead>
                                <tr>
                                    <td colSpan="2">Details</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Amount (BDT)</td>
                                    <td align="right">{data.amount}</td>
                                </tr>
                                <tr>
                                    <td>{`${
                                        isDownloading
                                            ? 'Amount due'
                                            : 'Discount'
                                    } (${data.discount || 0}%)`}</td>
                                    <td align="right">{getDiscountAmount()}</td>
                                </tr>
                                {/* {
                                <tr>
                                    <td>{`Trade Discount (${
                                        data.trade_discount || 0
                                    }%)`}</td>
                                    <td align="right">
                                        {getTradeDiscountAmount()}
                                    </td>
                                </tr>
                            } */}
                                <tr>
                                    <td>{`VAT (${data.vat || 0}%)`}</td>
                                    <td align="right">{getVatAmount()}</td>
                                </tr>
                                <tr>
                                    <td className="strong">Total (BDT)</td>
                                    <td className="strong" align="right">
                                        {data.total_amount}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {data.payment_mode && (
                            <div className="disclaimerNotes">
                                <div className="disclaimerNotesHeading">
                                    Payment Mode
                                </div>
                                <div className="disclaimerNotesDesc">
                                    {PAYMENT_MODE_OPTIONS.find(
                                        item => item.name === data.payment_mode
                                    )?.label || data?.payment_mode}
                                </div>
                            </div>
                        )}
                        <div className="disclaimerNotes">
                            <div className="disclaimerNotesHeading">Notes</div>
                            <div className="disclaimerNotesDesc">
                                {data?.notes}
                            </div>
                        </div>
                        <div className="disclaimerNotes textCenter">
                            {/* <div className="disclaimerNotesHeading">Notes</div> */}
                            <div className="disclaimerNotesDesc">
                                SOLD ITEMS CAN NOT BE EXCHANGED WITHOUT INVOICE
                            </div>

                            <div className="disclaimerNotesDesc mt-5 ">
                                <span className="boldText">Software By:</span>{' '}
                                DG ePharma
                                <span className="iconWrapper mr-0">
                                    <PhoneIcon />
                                </span>
                                {'09606501155'}
                            </div>
                            {/* <div className="disclaimerNotesDesc mt-8">
                                SOLD ITEMS CAN NOT BE EXCHANGED WITHOUT A
                                INVOICE
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="actionButton mt-5 dFlex fdColumn ai-flex-end">
                    <button
                        className="btn btnPrimary dFlex "
                        onClick={() => captureScreenshot()}>
                        <DownloadIcon />
                    </button>
                </div>
            </div>
        </SideDrawer>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

ViewSales.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSales)
