import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import './ProductReceiving.scss'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    ADD_PRODUCT_RECEIVING_STAGES,
    BILL_DETAILS_FIELDS,
    PAGE_CONST,
    PAYMENT_DETAILS_FIELDS,
    PAYMENT_MODE_OPTIONS_PRODUCT_RECEIVING,
    PRODUCT_DETAIL_FIELDS
} from '../../common/constant/page-constant'
import Card from '../Common/Card/Card'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import TrashIcon from '../../assets/TrashIcon'
import ArrowDown from '../../assets/ArrowDown'
import AddProduct from './AddProduct'
import ViewProduct from './ViewProduct'
import WizardSteps from '../../components/WizardSteps/WizardSteps'
import {
    HEADER_CONFIG,
    HEADER_CONST,
    WIZARD_STEPS
} from '../../common/constant/common-constant'
import AddIcon from '../../assets/AddIcon'
import CloseIcon from '../../assets/CloseIcon'
import Alert from '../../components/Alert/Alert'
import {
    addProductReceivingHelper,
    addSuppliersHelper,
    getInvoiceDetailsHelper,
    getMedicalCategoryHelper,
    getProductDataHelper,
    getProductReceivingHelper,
    getProductsHelper,
    getSuppliersHelper,
    updateProductDataHelper,
    updateSuppliersHelper
} from '../../helper/home-helper'
import { addProductReceivingAlertAction } from '../../modules/actions/action'
import ProductReceivingBlack from '../../assets/ProductReceivingBlack'
import ViewInventory from '../ManageInventory/ViewInventory'
import Header from '../Common/Header/Header'
import MobileHeader from '../Common/Header/MobileHeader'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import CheckPrimaryIcon from '../../assets/CheckPrimaryIcon'
import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import PopupCard from '../Common/PopupCard/PopupCard'
import { convertToTitleCase } from '../../common/utils/appUtils'

function AddProductReceiving(props) {
    const [wizardState, setWizardState] = useState(undefined)
    const [addProductReceivingState, setAddProductReceivingState] = useState(
        ADD_PRODUCT_RECEIVING_STAGES.BILL_DETAILS
    )
    const [addProductActive, setAddProductActive] = useState(false)
    const [addedProductVisibleId, setAddedProductDetailsId] = useState({
        id: undefined
    })
    const [isNextActive, setIsNextActive] = useState(false)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [serverError, setServerError] = useState(undefined)
    const [isViewProductActive, setViewProductActive] = useState(false)

    const [billDetails, setBillDetails] = useState({})
    const [productDetails, setProductDetails] = useState({})
    const [productDetailsArray, setProductDetailsArray] = useState([])
    const [paymentDetails, setPaymentDetails] = useState({})
    const [productData, setProductData] = useState(undefined)
    const [supplierData, setSupplierData] = useState(undefined)
    const [medicalCategoryList, setMedicalCategoryList] = useState(undefined)
    const [deleteProductPopup, setDeleteProductPopup] = useState(false)
    const [defaultProductDataArray, setDefaultProductDataArray] = useState([])
    const [defaultIdsAddedInArray, setDefaultIdsAddedInArray] = useState([])
    const pharmaCompanyMobileNo =
        billDetails?.[BILL_DETAILS_FIELDS.PHARMA_COMPANY]?.mobile_no
    const phoneNumber = billDetails?.[BILL_DETAILS_FIELDS.PHONE_NUMBER]
    const isPharmaCompanyEditable = !pharmaCompanyMobileNo

    useEffect(() => {
        handleWizardState(WIZARD_STEPS.STEP_1)
        // setAddProductReceivingState(ADD_PRODUCT_RECEIVING_STAGES.BILL_DETAILS)
        getSupplierData()
        getMedicalCategoryData()
    }, [])

    useEffect(() => {
        setServerError(props.serverError)
    }, [props.serverError])

    useEffect(() => {
        if (props.defaultData && supplierData) {
            let supplierId = props.defaultData?.supplierData?.id
            let sData = supplierData?.filter(obj => supplierId === obj.id)[0]

            if (!billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY]) {
                setBillDetails({
                    [BILL_DETAILS_FIELDS.PHARMA_COMPANY]: sData,
                    [BILL_DETAILS_FIELDS.IS_APPROVED]: sData?.is_approved
                })
            }

            let defaultProductDataArray = props.defaultData?.productArray
            if (defaultProductDataArray?.length > 0) {
                // pre selecting the first product
                let pDetails = {
                    ...defaultProductDataArray[0],
                    [PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]:
                        defaultProductDataArray[0]
                }

                setProductDetails(pDetails)
                defaultIdsAddedInArray.push(defaultProductDataArray[0].id)
                setDefaultProductDataArray(defaultProductDataArray)
            }
        }
    }, [props.defaultData, supplierData])

    useEffect(() => {
        setServerError(undefined)
        let aProductReceivingState = addProductReceivingState

        switch (aProductReceivingState) {
            case ADD_PRODUCT_RECEIVING_STAGES.BILL_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_1)
                break
            case ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS:
                if (productDetailsArray.length <= 0) setAddProductActive(true)
                else setAddProductActive(false)
                handleWizardState(WIZARD_STEPS.STEP_2)
                break
            case ADD_PRODUCT_RECEIVING_STAGES.PAYMENT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_3)
                handlePaymentDetailsUpdate(
                    PAYMENT_DETAILS_FIELDS.AMOUNT,
                    getProductReceivingAmount()
                )

                handlePaymentDetailsUpdate(
                    PAYMENT_DETAILS_FIELDS.VAT,
                    getVatAmount()
                )
                break
        }
    }, [addProductReceivingState])

    useEffect(() => {
        let totalAmount = getTotalAmount()

        // handlePaymentDetailsUpdate(PAYMENT_DETAILS_FIELDS.VAT, getVatAmount())

        if (
            totalAmount != paymentDetails?.[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]
        ) {
            handlePaymentDetailsUpdate(
                PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT,
                getTotalAmount()
            )
        }
    }, [
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT],
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.VAT],
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_AMOUNT],
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE]
    ])

    const getProductData = productCategory => {
        let supplier_id = billDetails?.[BILL_DETAILS_FIELDS.PHARMA_COMPANY].id
        let product_type = productCategory

        let data = {
            supplier_id,
            product_type
        }

        setProductData([])
        getProductDataHelper(data)
            .then(res => {
                setProductData([...res])
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getMedicalCategoryData = () => {
        getMedicalCategoryHelper()
            .then(res => {
                setMedicalCategoryList([...res])
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getSupplierData = () => {
        getSuppliersHelper()
            .then(res => {
                // console.log('res splr: ', res)
                setSupplierData(res)
            })
            .catch(err => {
                console.log('err prd: ', err)
            })
    }

    const handleWizardState = state => {
        switch (state) {
            case WIZARD_STEPS.STEP_3:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: true,
                    [WIZARD_STEPS.STEP_2]: true,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
            case WIZARD_STEPS.STEP_2:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: true,
                    [WIZARD_STEPS.STEP_2]: false,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
            case WIZARD_STEPS.STEP_1:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: false,
                    [WIZARD_STEPS.STEP_2]: false,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
        }
    }

    const onWizardStepClick = step => {
        switch (step) {
            case WIZARD_STEPS.STEP_1:
                setAddProductReceivingState(
                    ADD_PRODUCT_RECEIVING_STAGES.BILL_DETAILS
                )
                break
            case WIZARD_STEPS.STEP_2:
                setAddProductReceivingState(
                    ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS
                )
                break
            case WIZARD_STEPS.STEP_3:
                setAddProductReceivingState(
                    ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS
                )
                break
        }
    }

    const handleNextClick = async () => {
        if (
            addProductActive &&
            addProductReceivingState ===
                ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS
        ) {
            handleAddProductClick()
            return
        }

        const isValidState = checkValidation()
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        switch (addProductReceivingState) {
            case ADD_PRODUCT_RECEIVING_STAGES.BILL_DETAILS:
                // let callback = () => {
                //     setAddProductReceivingState(
                //         ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS
                //     )
                // }

                setServerError(undefined)
                try {
                    await updateSupplierDetails()
                    setAddProductReceivingState(
                        ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS
                    )
                } catch (errMsg) {
                    setServerError(errMsg)
                }

                break
            case ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS:
                setAddProductReceivingState(
                    ADD_PRODUCT_RECEIVING_STAGES.PAYMENT_DETAILS
                )
                break
            case ADD_PRODUCT_RECEIVING_STAGES.PAYMENT_DETAILS:
                handleSubmit()
                break
        }
    }

    const updateSupplierDetails = async () => {
        if (
            billDetails[BILL_DETAILS_FIELDS.ADDRESS] ||
            billDetails[BILL_DETAILS_FIELDS.PHONE_NUMBER]
            // && billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY]?.id
        ) {
            // updating supplier details

            let data = {
                id: billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY]?.id,
                mobile_no: billDetails[BILL_DETAILS_FIELDS.PHONE_NUMBER],
                address: billDetails[BILL_DETAILS_FIELDS.ADDRESS]
            }

            if (billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY]?.id) {
                try {
                    let res = await updateSuppliersHelper(data)
                    setBillDetails({
                        ...billDetails,
                        [BILL_DETAILS_FIELDS.ADDRESS]: undefined,
                        [BILL_DETAILS_FIELDS.PHONE_NUMBER]: undefined,
                        [BILL_DETAILS_FIELDS.PHARMA_COMPANY]: {
                            ...billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY],
                            mobile_no:
                                billDetails[BILL_DETAILS_FIELDS.PHONE_NUMBER],
                            address: billDetails[BILL_DETAILS_FIELDS.ADDRESS]
                        }
                    })
                } catch (errMsg) {
                    throw errMsg
                }

                return
            }

            // adding supplier details
            data.name = billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY].name

            try {
                let id = await addSuppliersHelper(data)

                console.log(id)
                setBillDetails({
                    ...billDetails,
                    [BILL_DETAILS_FIELDS.ADDRESS]: undefined,
                    [BILL_DETAILS_FIELDS.PHONE_NUMBER]: undefined,
                    [BILL_DETAILS_FIELDS.PHARMA_COMPANY]: {
                        ...data,
                        id: id
                    }
                })

                getSupplierData()
            } catch (errMsg) {
                throw errMsg
            }
        }
    }

    const handleSubmit = async () => {
        let data = {
            billDetail: {
                invoice_number: billDetails[BILL_DETAILS_FIELDS.INVOICE_NUMBER],
                supplier_id: billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY]?.id
            },
            productDetails: productDetailsArray,
            paymentDetails: paymentDetails
        }

        props.handleSubmit(data)
    }

    const checkValidation = () => {
        // return true;
        if (
            addProductReceivingState ===
            ADD_PRODUCT_RECEIVING_STAGES.BILL_DETAILS
        ) {
            if (billDetails) {
                if (
                    billDetails[BILL_DETAILS_FIELDS.INVOICE_NUMBER] &&
                    // billDetails[BILL_DETAILS_FIELDS.DATE] &&
                    billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY] &&
                    (billDetails[BILL_DETAILS_FIELDS.PHONE_NUMBER] ||
                        billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY]?.[
                            BILL_DETAILS_FIELDS.PHONE_NUMBER
                        ]) &&
                    (billDetails[BILL_DETAILS_FIELDS.ADDRESS] ||
                        billDetails[BILL_DETAILS_FIELDS.PHARMA_COMPANY]?.[
                            BILL_DETAILS_FIELDS.ADDRESS
                        ])
                ) {
                    return true
                }
            }

            console.log('bill validation failed')
            return false
        } else if (
            addProductReceivingState ===
            ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS
        ) {
            if (productDetailsArray && productDetailsArray.length > 0) {
                let allValid = productDetailsArray.every(product => {
                    if (
                        product[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] &&
                        product[PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY] &&
                        product[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME] &&
                        product[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY] &&
                        product[PRODUCT_DETAIL_FIELDS.BATCH_NUMBER] &&
                        product[PRODUCT_DETAIL_FIELDS.EXPIRY_DATE] &&
                        (product[PRODUCT_DETAIL_FIELDS.QUANTITY] ||
                            (product[PRODUCT_DETAIL_FIELDS.NUMBER_OF_STRIP] &&
                                product[
                                    PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                                ])) &&
                        product[PRODUCT_DETAIL_FIELDS.DOSE]
                    )
                        return true
                })

                if (allValid) return true

                console.log('product validation failed')
                return false
            }
        } else if (
            addProductReceivingState ===
            ADD_PRODUCT_RECEIVING_STAGES.PAYMENT_DETAILS
        ) {
            if (paymentDetails) {
                if (
                    paymentDetails[PAYMENT_DETAILS_FIELDS.AMOUNT] &&
                    // paymentDetails[PAYMENT_DETAILS_FIELDS.DISCOUNT] &&
                    // paymentDetails[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT] &&
                    // paymentDetails[PAYMENT_DETAILS_FIELDS.VAT] &&
                    paymentDetails[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT] &&
                    paymentDetails[PAYMENT_DETAILS_FIELDS.PAYMENT_MODE]
                    // && paymentDetails[PAYMENT_DETAILS_FIELDS.RECEIVED_BY]
                ) {
                    return true
                }
            }

            console.log('payments validation failed')
            return false
        }
    }

    const checkAddProductValidation = () => {
        if (productDetails) {
            if (
                productDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY] &&
                productDetails[PRODUCT_DETAIL_FIELDS.BATCH_NUMBER] &&
                productDetails[PRODUCT_DETAIL_FIELDS.EXPIRY_DATE] &&
                (productDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] ||
                    productDetails[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
                        PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                    ]) &&
                // (productDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE]?.name ||
                //     productDetails[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
                //         PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE
                //     ]?.name) &&
                (productDetails[PRODUCT_DETAIL_FIELDS.QUANTITY] ||
                    (productDetails[PRODUCT_DETAIL_FIELDS.NUMBER_OF_STRIP] &&
                        (productDetails[PRODUCT_DETAIL_FIELDS.MED_PER_STRIP] ||
                            productDetails[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.MED_PER_STRIP]))) &&
                (productDetails[PRODUCT_DETAIL_FIELDS.DOSE] ||
                    productDetails[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
                        PRODUCT_DETAIL_FIELDS.DOSE
                    ])
            ) {
                return true
            }
        }

        console.log('validation failed')

        return false
    }

    const handleAddProductClick = async () => {
        const isValidState = checkAddProductValidation()
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        console.log('productDetails: ', productDetails)
        let { product_image, selectedProduct, ...pDetails } = productDetails
        let product_details = {
            ...selectedProduct,
            ...pDetails
        }

        let data = {
            supplier_id: billDetails?.[BILL_DETAILS_FIELDS.PHARMA_COMPANY].id,
            product_details: product_details
        }

        console.log(data)

        let dataStringify = JSON.stringify(data)

        let formData = new FormData()
        formData.append('data', dataStringify)
        if (product_image) formData.append('product_image', product_image)

        try {
            let res = await updateProductDataHelper(formData)
            console.log('update product res : ', res)

            let pdArray = productDetailsArray
            pdArray.push({
                ...product_details,
                ...res
            })

            setProductDetailsArray(pdArray)

            /** handle default product */
            if (
                defaultProductDataArray.length !== defaultIdsAddedInArray.length
            ) {
                setAddProductActive(false)

                let unaddedProduct = defaultProductDataArray.find(
                    item => !defaultIdsAddedInArray.includes(item.id)
                )

                let pDetails = {
                    ...unaddedProduct,
                    [PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]: unaddedProduct
                }

                setProductDetails(pDetails)
                setAddProductActive(true)
                defaultIdsAddedInArray.push(unaddedProduct.id)
            } else {
                setProductDetails({})
                setAddProductActive(false)
            }
        } catch (errMsg) {
            setServerError(errMsg)
        }
    }

    const handleRemoveProductClick = product => {
        let pdArray = [...productDetailsArray]

        const objWithIdIndex = pdArray.findIndex(obj => obj.id === product.id)

        if (objWithIdIndex > -1) {
            pdArray.splice(objWithIdIndex, 1)
        }

        setProductDetailsArray(pdArray)
        setDeleteProductPopup(false)
    }

    const handleProductDetailsUpdate = (id, key, value) => {
        let pDetails = { ...productDetails }
        // if (!pDetails?.id) {
        //     pDetails = {
        //         id: Math.floor(Math.random() * 100)
        //     }
        // }
        if (key === PRODUCT_DETAIL_FIELDS.QUANTITY) {
            pDetails.number_of_strip =
                value * pDetails[PRODUCT_DETAIL_FIELDS.MED_PER_STRIP]
        } else if (key === PRODUCT_DETAIL_FIELDS.MED_PER_STRIP) {
            pDetails.number_of_strip =
                value * pDetails[PRODUCT_DETAIL_FIELDS.QUANTITY]
        }

        if (key === PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY) {
            pDetails = {
                // id: Math.floor(Math.random() * 100)
            }
            getProductData(value)
        }

        if (
            key === PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT ||
            key === PRODUCT_DETAIL_FIELDS.QUANTITY
        ) {
            let newCostPerUnit
            const newVal = value || 0

            if (key === PRODUCT_DETAIL_FIELDS.QUANTITY) {
                newCostPerUnit =
                    pDetails[PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT] / newVal || 0
            }

            if (key === PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT) {
                newCostPerUnit =
                    newVal / pDetails[PRODUCT_DETAIL_FIELDS.QUANTITY] || 0
            }

            pDetails.cost_per_unit = newCostPerUnit.toFixed(2)
        }

        let newAmount = pDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_COST] || 0

        if (key === PRODUCT_DETAIL_FIELDS.PRODUCT_COST) {
            let amt = value || 0
            let vat =
                pDetails[PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE] ||
                pDetails?.[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
                    PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE
                ] ||
                0

            newAmount = parseFloat(amt) + parseFloat((amt * vat) / 100) || 0

            // (parseFloat(value) || 0) +
            // (parseFloat(pDetails[PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE]) ||
            //     parseFloat(
            //         pDetails?.[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
            //             PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE
            //         ]
            //     ) ||
            //     0)
        } else if (
            key === PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE ||
            key === PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT ||
            key === PRODUCT_DETAIL_FIELDS.QUANTITY
        ) {
            let amt
            let vat = pDetails[PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE] || 0
            if (key === PRODUCT_DETAIL_FIELDS.QUANTITY) {
                amt = pDetails[PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT] / value
            } else if (key === PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT) {
                amt = value / pDetails[PRODUCT_DETAIL_FIELDS.QUANTITY]
            } else if (key === PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE) {
                vat = value

                amt =
                    pDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_COST] ||
                    pDetails?.[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
                        PRODUCT_DETAIL_FIELDS.PRODUCT_COST
                    ] ||
                    0
            }

            newAmount = parseFloat(amt) + parseFloat((amt * vat) / 100) || 0

            // (parseFloat(pDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_COST]) ||
            //     parseFloat(
            //         pDetails?.[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
            //             PRODUCT_DETAIL_FIELDS.PRODUCT_COST
            //         ]
            //     ) ||
            //     0) + (parseFloat(value) || 0)
        }
        // else if (key === PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT) {
        //     newAmount = value?.[PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST] || 0
        // }

        let details = {
            ...pDetails,
            [key]: value,
            [PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST]:
                parseFloat(newAmount).toFixed(2)
        }

        setProductDetails({
            ...details
        })
    }

    const handleBillDetailUpdate = (key, value) => {
        setBillDetails({
            ...billDetails,
            [key]: value
        })

        if (key === BILL_DETAILS_FIELDS.PHARMA_COMPANY) {
            setProductDetails(undefined)
            setProductDetailsArray([])
        }
    }

    const handlePaymentDetailsUpdate = (key, value) => {
        setPaymentDetails(prevDetails => {
            let updatedDetails = { ...prevDetails }

            // Update the payment details based on the key and value
            if (key === PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_AMOUNT) {
                let tradePercentage = parseFloat(
                    (value / prevDetails[PAYMENT_DETAILS_FIELDS.AMOUNT]) * 100
                ).toFixed(2)
                updatedDetails = {
                    ...updatedDetails,
                    [PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE]:
                        tradePercentage
                }
            } else if (
                key === PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE
            ) {
                let tradeAmount = parseFloat(
                    (value / 100) * prevDetails[PAYMENT_DETAILS_FIELDS.AMOUNT]
                ).toFixed(2)
                updatedDetails = {
                    ...updatedDetails,
                    [PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_AMOUNT]: tradeAmount
                }
            }

            // Finally, update the key that was passed in
            updatedDetails[key] = value

            console.log('Updated payment details:', updatedDetails)
            console.log(updatedDetails)
            return updatedDetails
        })

        // let pDetails = paymentDetails
        // let amount = pDetails[PAYMENT_DETAILS_FIELDS.AMOUNT]

        // if (key === PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_AMOUNT) {
        //     let tradePercentage = parseFloat((value / amount) * 100).toFixed(2)

        //     pDetails = {
        //         ...pDetails,
        //         [PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE]:
        //             tradePercentage
        //     }
        // } else if (key === PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE) {
        //     let tradeAmount = parseFloat((value / 100) * amount).toFixed(2)

        //     pDetails = {
        //         ...pDetails,
        //         [PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_AMOUNT]: tradeAmount
        //     }
        // }

        // console.log(
        //     'in set payment details - pDetails, key, value: ',
        //     pDetails,
        //     key,
        //     value
        // )

        // setPaymentDetails(prevDetails => {
        //     return { ...prevDetails, ...pDetails, [key]: value }
        // })
        // setPaymentDetails({
        //     ...pDetails,
        //     [key]: value
        // })
    }

    const handleExistingProductUpdate = (id = undefined, key, value) => {
        let pDetailsArray = productDetailsArray || []

        let productIndex = pDetailsArray.findIndex(obj => obj.id == id)
        if (productIndex > -1) {
            pDetailsArray[productIndex][key] = value

            if (
                key === PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT ||
                key === PRODUCT_DETAIL_FIELDS.QUANTITY
            ) {
                let newCostPerUnit
                const newVal = value || 0

                if (key === PRODUCT_DETAIL_FIELDS.QUANTITY) {
                    newCostPerUnit =
                        pDetailsArray[productIndex][
                            PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT
                        ] / newVal || 0
                }

                if (key === PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT) {
                    newCostPerUnit =
                        newVal /
                            pDetailsArray[productIndex][
                                PRODUCT_DETAIL_FIELDS.QUANTITY
                            ] || 0
                }

                let cost_per_unit = newCostPerUnit.toFixed(2)
                let vat =
                    pDetailsArray[productIndex][
                        PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE
                    ] || 0
                let new_cost_per_unit =
                    parseFloat(cost_per_unit) +
                        parseFloat((cost_per_unit * vat) / 100) || 0

                pDetailsArray[productIndex][
                    PRODUCT_DETAIL_FIELDS.PRODUCT_COST
                ] = parseFloat(cost_per_unit).toFixed(2)

                pDetailsArray[productIndex][
                    PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST
                ] = parseFloat(new_cost_per_unit).toFixed(2)
            }
        } else {
            let newProduct = {
                id: Math.floor(Math.random() * 100),
                [key]: value
            }

            pDetailsArray.push(newProduct)
        }
        // (parseFloat(pDetailsArray[PRODUCT_DETAIL_FIELDS.PRODUCT_COST]) ||
        //     parseFloat(
        //         pDetailsArray?.[PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT]?.[
        //             PRODUCT_DETAIL_FIELDS.PRODUCT_COST
        //         ]
        //     ) ||
        //     0) + (parseFloat(value) || 0)

        setProductDetailsArray([...pDetailsArray])
    }

    const getProductReceivingAmount = () => {
        let pdArray = productDetailsArray

        let amount = 0

        pdArray.map(productDetail => {
            console.log('productDetail: ', productDetail)

            amount += parseFloat(
                productDetail[PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT]
            )
        })

        // paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT]

        return parseFloat(amount).toFixed(2)
    }

    const getVatAmount = () => {
        let vat = 0
        productDetailsArray.map(productDetail => {
            let amount = productDetail[PRODUCT_DETAIL_FIELDS.PRODUCT_COST]
            let quantity = productDetail[PRODUCT_DETAIL_FIELDS.QUANTITY]

            let totalProductAmount = amount * quantity

            vat += parseFloat(
                totalProductAmount *
                    (productDetail[PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE] / 100)
            )

            // vat += parseFloat(
            //     productDetail[PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE]
            // )
        })

        console.log('vat amount: ', vat)

        return vat
    }

    const getTotalAmount = () => {
        let initialAmount = paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT]
        let tradeDiscountedAmount =
            paymentDetails?.[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_AMOUNT] || 0
        let vat = getVatAmount()

        let remainingAmount = initialAmount - tradeDiscountedAmount + vat

        return remainingAmount
    }

    return (
        <>
            <SideDrawer
                handleDrawerToggle={props.handleDrawerToggle}
                open={props.open}
                drawerButton={true}
                drawerHeading="New Product Receiving"
                isNextActive={isNextActive}
                buttonText={
                    addProductActive &&
                    addProductReceivingState ===
                        ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS
                        ? 'Save'
                        : 'Next'
                }
                handleClick={() => handleNextClick()}>
                <WizardSteps
                    wizardState={wizardState}
                    onStepClick={step => onWizardStepClick(step)}
                />
                <div className="wizardFieldset">
                    {serverError && (
                        <div class="invalidFeedback mb-4">{serverError}</div>
                    )}
                    {addProductReceivingState ===
                        ADD_PRODUCT_RECEIVING_STAGES.BILL_DETAILS && (
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="formInline dualInlineGroup dFlex">
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="invoice"
                                        value={
                                            billDetails?.[
                                                BILL_DETAILS_FIELDS
                                                    .INVOICE_NUMBER
                                            ]
                                        }
                                        placeholder="Invoice Number"
                                        onChange={e =>
                                            handleBillDetailUpdate(
                                                BILL_DETAILS_FIELDS.INVOICE_NUMBER,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="invoice">Invoice Number</label>
                                    {isFieldErrorVisible &&
                                        !billDetails?.[
                                            BILL_DETAILS_FIELDS.INVOICE_NUMBER
                                        ] && (
                                            <div class="invalidFeedback">
                                                Please select a valid Invoice
                                                Number.
                                            </div>
                                        )}
                                </div>
                                {/* <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="date"
                                            className="formControl"
                                            id="date"
                                            placeholder="Date"
                                            value={billDetails?.[BILL_DETAILS_FIELDS.DATE]}
                                            onChange={(e) =>
                                            handleBillDetailUpdate(
                                                BILL_DETAILS_FIELDS.DATE,
                                                e.target.value
                                            )
                                            }
                                            required
                                        />
                                        <label for="date">Date</label>
                                        {isFieldErrorVisible &&
                                            !billDetails?.[BILL_DETAILS_FIELDS.DATE] && (
                                            <div class="invalidFeedback">
                                                Please select a valid Date.
                                            </div>
                                            )}
                                    </div> */}
                            </div>
                            <div className="formGroup formFloating fgrow1 maxWidth">
                                <CreatableSelect
                                    className="formSelect reactSelect"
                                    id="floatingSelect"
                                    value={
                                        billDetails?.[
                                            BILL_DETAILS_FIELDS.PHARMA_COMPANY
                                        ]
                                    }
                                    onChange={option =>
                                        handleBillDetailUpdate(
                                            BILL_DETAILS_FIELDS.PHARMA_COMPANY,
                                            option
                                        )
                                    }
                                    options={supplierData}
                                    getOptionLabel={option =>
                                        option.name || option.label
                                    }
                                    placeholder="Select Pharma Company"
                                    styles={{
                                        control: base => ({
                                            border: 0,
                                            display: 'flex',
                                            paddingTop: '20px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused
                                                ? 'rgb(222, 235, 255)'
                                                : state.isSelected
                                                ? '#FFF'
                                                : 'inherit',
                                            color: '#212529',
                                            '&:hover': {
                                                backgroundColor:
                                                    state.isSelected
                                                        ? '#DEEBFF'
                                                        : 'rgb(222, 235, 255)'
                                            }
                                        })
                                    }}
                                    onCreateOption={newOption => {
                                        handleBillDetailUpdate(
                                            BILL_DETAILS_FIELDS.IS_APPROVED,
                                            false
                                        )

                                        handleBillDetailUpdate(
                                            BILL_DETAILS_FIELDS.PHARMA_COMPANY,
                                            { name: newOption }
                                        )
                                    }}
                                    // menuIsOpen={true}
                                    isClearable
                                />
                                <label for="floatingSelect">
                                    Pharma Company
                                </label>
                                {isFieldErrorVisible &&
                                    !billDetails?.[
                                        BILL_DETAILS_FIELDS.PHARMA_COMPANY
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please choose Pharma Company.
                                        </div>
                                    )}
                            </div>
                            <div className="formInline dFlex flexNowrap">
                                <div className="formGroup formFloating fgrow1 maxWidth">
                                    <select
                                        className="formSelect"
                                        id="floatingSelect">
                                        {/* <option value="1">+91</option> */}
                                        <option value="2">+88</option>
                                    </select>
                                    <label for="floatingSelect">Country</label>
                                    {false && (
                                        <div class="invalidFeedback">
                                            Please choose country code.
                                        </div>
                                    )}
                                </div>
                                <div className="formGroup formFloating ml-4 fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="phone"
                                        placeholder="Phone"
                                        disabled={!isPharmaCompanyEditable}
                                        value={
                                            phoneNumber ||
                                            pharmaCompanyMobileNo ||
                                            ''
                                        }
                                        onChange={e =>
                                            handleBillDetailUpdate(
                                                BILL_DETAILS_FIELDS.PHONE_NUMBER,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="phone">Phone</label>
                                    {isFieldErrorVisible &&
                                        !billDetails?.[
                                            BILL_DETAILS_FIELDS.PHONE_NUMBER
                                        ] &&
                                        !pharmaCompanyMobileNo && (
                                            <div class="invalidFeedback">
                                                Please select a valid Phone.
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="formGroup formFloating">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="address"
                                    placeholder="Address"
                                    disabled={!isPharmaCompanyEditable}
                                    value={
                                        billDetails?.[
                                            BILL_DETAILS_FIELDS.ADDRESS
                                        ] ||
                                        billDetails?.[
                                            BILL_DETAILS_FIELDS.PHARMA_COMPANY
                                        ]?.address ||
                                        ''
                                    }
                                    onChange={e =>
                                        handleBillDetailUpdate(
                                            BILL_DETAILS_FIELDS.ADDRESS,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label for="address">Address</label>
                                {isFieldErrorVisible &&
                                    !billDetails?.[
                                        BILL_DETAILS_FIELDS.ADDRESS
                                    ] &&
                                    !billDetails?.[
                                        BILL_DETAILS_FIELDS.PHARMA_COMPANY
                                    ]?.address && (
                                        <div class="invalidFeedback">
                                            Please enter a valid Address.
                                        </div>
                                    )}
                            </div>
                        </form>
                    )}
                    {addProductReceivingState ===
                        ADD_PRODUCT_RECEIVING_STAGES.PRODUCT_DETAILS && (
                        <div className="addedProductsListWrap">
                            <ul className="addedProductsList">
                                {productDetailsArray?.length > 0 &&
                                    productDetailsArray.map(product => {
                                        return (
                                            <li className="addedProduct">
                                                <div className="dFlex jcSpaceBetween aiCenter">
                                                    <div className="addedProductName">
                                                        {
                                                            product[
                                                                PRODUCT_DETAIL_FIELDS
                                                                    .PRODUCT_NAME
                                                            ]
                                                        }
                                                    </div>
                                                    <div className="addedProductAction dFlex">
                                                        <div
                                                            className="trashIcon"
                                                            onClick={() =>
                                                                setDeleteProductPopup(
                                                                    true
                                                                )
                                                            }>
                                                            <TrashIcon />
                                                        </div>
                                                        <div
                                                            className={`toggleArrow ${
                                                                product.id ===
                                                                addedProductVisibleId
                                                                    ? 'show'
                                                                    : 'hide'
                                                            }`}
                                                            onClick={() => {
                                                                if (
                                                                    product.id ===
                                                                    addedProductVisibleId
                                                                )
                                                                    setAddedProductDetailsId(
                                                                        undefined
                                                                    )
                                                                else
                                                                    setAddedProductDetailsId(
                                                                        product.id
                                                                    )
                                                            }}>
                                                            <ArrowDown />
                                                        </div>
                                                    </div>
                                                </div>
                                                {!product.is_approved && (
                                                    <div>
                                                        Product approval pending
                                                        from DG HealthCare
                                                    </div>
                                                )}

                                                {!billDetails?.[
                                                    BILL_DETAILS_FIELDS
                                                        .IS_APPROVED
                                                ] &&
                                                    !supplierData?.find(
                                                        supplier =>
                                                            supplier?.id ===
                                                            billDetails?.[
                                                                BILL_DETAILS_FIELDS
                                                                    .PHARMA_COMPANY
                                                            ]?.id
                                                    )?.is_approved && (
                                                        <div>
                                                            Supplier approval
                                                            pending from DG
                                                            HealthCare
                                                        </div>
                                                    )}
                                                {deleteProductPopup && (
                                                    <PopupCard
                                                        header="Delete Product 1?"
                                                        body="Lorem ipsum dolor sit t consectetur. Nulla bibendum ac ornare sodales.?">
                                                        <button
                                                            className="btn btnPrimaryOutlined dFlex"
                                                            onClick={() =>
                                                                setDeleteProductPopup(
                                                                    false
                                                                )
                                                            }>
                                                            Cancel
                                                        </button>
                                                        <button
                                                            className="btn btnPrimary dFlex"
                                                            onClick={() =>
                                                                handleRemoveProductClick(
                                                                    product
                                                                )
                                                            }>
                                                            Delete
                                                        </button>
                                                    </PopupCard>
                                                )}
                                                {addedProductVisibleId ===
                                                    product.id && (
                                                    <AddProduct
                                                        isFieldErrorVisible={
                                                            isFieldErrorVisible
                                                        }
                                                        handleProductUpdate={(
                                                            id,
                                                            key,
                                                            value
                                                        ) =>
                                                            handleExistingProductUpdate(
                                                                id,
                                                                key,
                                                                value
                                                            )
                                                        }
                                                        isViewOnlyProduct={true}
                                                        product={product}
                                                        productData={
                                                            productData
                                                        }
                                                        medicalCategoryList={
                                                            medicalCategoryList
                                                        }
                                                    />
                                                )}
                                            </li>
                                        )
                                    })}
                                {addProductActive && (
                                    <li className="addedProduct">
                                        <AddProduct
                                            isFieldErrorVisible={
                                                isFieldErrorVisible
                                            }
                                            handleProductUpdate={(
                                                id,
                                                key,
                                                value
                                            ) =>
                                                handleProductDetailsUpdate(
                                                    id,
                                                    key,
                                                    value
                                                )
                                            }
                                            clearProduct={() => {
                                                setProductDetails(undefined)
                                                setAddProductActive(false)
                                            }}
                                            product={productDetails}
                                            productData={productData}
                                            medicalCategoryList={
                                                medicalCategoryList
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                            {!addProductActive && (
                                <div
                                    className="addItemButton"
                                    onClick={() => {
                                        setIsFieldErrorVisible(false)
                                        setAddProductActive(true)
                                    }}>
                                    <button className="btn btnPrimary dFlex">
                                        <AddIcon />
                                    </button>
                                </div>
                            )}
                            {/* {!addProductActive && <div className='plus-icon' onClick={() => setAddProductActive(true)}>+ icon</div>} */}
                        </div>
                    )}
                    {addProductReceivingState ===
                        ADD_PRODUCT_RECEIVING_STAGES.PAYMENT_DETAILS && (
                        // <div className="wizardFieldset active">
                        <form>
                            <div className="formGroup formFloating">
                                <textarea
                                    rows="2"
                                    type="text"
                                    className="formControl"
                                    id="notes"
                                    value={
                                        paymentDetails?.[
                                            PAYMENT_DETAILS_FIELDS.NOTES
                                        ]
                                    }
                                    onChange={e =>
                                        handlePaymentDetailsUpdate(
                                            PAYMENT_DETAILS_FIELDS.NOTES,
                                            e.target.value
                                        )
                                    }
                                    required></textarea>
                                <label for="notes">Notes</label>
                                {false &&
                                    !paymentDetails?.[
                                        PAYMENT_DETAILS_FIELDS.NOTES
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please enter notes.
                                        </div>
                                    )}
                            </div>
                            <div className="formGroup formFloating formInputText">
                                <div className="preInputGroup backColor">
                                    <div className="preInputText">BDT</div>
                                </div>
                                <input
                                    type="text"
                                    className="formControl"
                                    id="amount"
                                    placeholder="Amount"
                                    value={
                                        paymentDetails?.[
                                            PAYMENT_DETAILS_FIELDS.AMOUNT
                                        ]
                                    }
                                    readOnly={true}
                                    onChange={e =>
                                        handlePaymentDetailsUpdate(
                                            PAYMENT_DETAILS_FIELDS.AMOUNT,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label for="amount">Amount(BDT)</label>
                                {isFieldErrorVisible &&
                                    !paymentDetails?.[
                                        PAYMENT_DETAILS_FIELDS.AMOUNT
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please enter a valid Amount.
                                        </div>
                                    )}
                            </div>
                            {/* <div className="formInline dFlex flexNowrap">
                                <div className="formGroup formFloating formInputText">
                                    <div className="preInputGroup backColor">
                                        <div className="preInputText">%</div>
                                    </div>
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="amount"
                                        placeholder="Discount"
                                        value={
                                            paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS.DISCOUNT
                                            ]
                                        }
                                        onChange={e =>
                                            handlePaymentDetailsUpdate(
                                                PAYMENT_DETAILS_FIELDS.DISCOUNT,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="amount">Discount(%)</label>
                                    {isFieldErrorVisible &&
                                        !paymentDetails?.[
                                            PAYMENT_DETAILS_FIELDS.DISCOUNT
                                        ] && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Discount.
                                            </div>
                                        )}
                                </div>
                                <div className="amountPercentage">
                                    <div className="currencyType">BDT</div>
                                    <div className="currencyValue">
                                        {getDiscountedAmount()}
                                    </div>
                                </div>
                            </div> */}
                            <div className="formInline dFlex flexNowrap">
                                <div className="formGroup formFloating formInputText fgrow1">
                                    <div className="preInputGroup backColor">
                                        <div className="preInputText">%</div>
                                    </div>
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="amount"
                                        placeholder="Trade Discount(%)"
                                        value={
                                            paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS
                                                    .TRADE_DISCOUNT_PERCENTAGE
                                            ]
                                        }
                                        onChange={e =>
                                            handlePaymentDetailsUpdate(
                                                PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="amount">
                                        Trade Discount(%)
                                    </label>
                                    {isFieldErrorVisible &&
                                        !paymentDetails?.[
                                            PAYMENT_DETAILS_FIELDS
                                                .TRADE_DISCOUNT_PERCENTAGE
                                        ] && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Trade
                                                Discount.
                                            </div>
                                        )}
                                </div>
                                <div className="formGroup formFloating formInputText fgrow1 ml-4">
                                    <div className="preInputGroup backColor">
                                        <div className="preInputText">BDT</div>
                                    </div>
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="amount"
                                        placeholder="Trade Discount"
                                        value={
                                            paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS
                                                    .TRADE_DISCOUNT_AMOUNT
                                            ]
                                        }
                                        onChange={e =>
                                            handlePaymentDetailsUpdate(
                                                PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_AMOUNT,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="amount">Trade Discount</label>
                                    {isFieldErrorVisible &&
                                        !paymentDetails?.[
                                            PAYMENT_DETAILS_FIELDS
                                                .TRADE_DISCOUNT_AMOUNT
                                        ] && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Trade
                                                Discount Amount.
                                            </div>
                                        )}
                                </div>
                                {/* <div className="amountPercentage">
                                    <div className="currencyType">BDT</div>
                                    <div className="currencyValue">
                                        {getTradeDiscountedAmount()}
                                    </div>
                                </div> */}
                            </div>
                            <div className="formInline dFlex flexNowrap ">
                                <div className="formGroup formFloating formInputText fgrow1">
                                    <div className="preInputGroup backColor">
                                        <div className="preInputText">BDT</div>
                                    </div>
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="amount"
                                        placeholder="VAT (BDT)"
                                        readOnly={true}
                                        value={
                                            paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS.VAT
                                            ]
                                        }
                                        onChange={e =>
                                            handlePaymentDetailsUpdate(
                                                PAYMENT_DETAILS_FIELDS.VAT,
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                    <label for="amount">VAT (BDT)</label>
                                </div>
                                {/* <div className="amountPercentage">
                                    <div className="currencyType">BDT</div>
                                    <div className="currencyValue">
                                        {getVatAmount()}
                                    </div>
                                </div> */}
                            </div>
                            <div className="formGroup formFloating formInputText">
                                <div className="preInputGroup backColor">
                                    <div className="preInputText">BDT</div>
                                </div>
                                <input
                                    type="text"
                                    className="formControl"
                                    id="totalamount"
                                    placeholder="Total Amount"
                                    readOnly={true}
                                    value={
                                        paymentDetails?.[
                                            PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT
                                        ]
                                    }
                                    // onChange={(e) => handlePaymentDetailsUpdate(PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT, e.target.value)}
                                    required
                                />
                                <label for="totalamount">
                                    Total Amount(BDT)
                                </label>
                                {isFieldErrorVisible &&
                                    !paymentDetails?.[
                                        PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please enter a valid Total Amount.
                                        </div>
                                    )}
                            </div>
                            <div className="formGroup formFloating fgrow1 maxWidth">
                                <Select
                                    className="formSelect reactSelect"
                                    id="floatingSelect"
                                    value={{
                                        label: convertToTitleCase(
                                            paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS
                                                    .PAYMENT_MODE
                                            ]
                                        )
                                    }}
                                    onChange={option =>
                                        handlePaymentDetailsUpdate(
                                            PAYMENT_DETAILS_FIELDS.PAYMENT_MODE,
                                            option.name
                                        )
                                    }
                                    options={
                                        PAYMENT_MODE_OPTIONS_PRODUCT_RECEIVING
                                    }
                                    getOptionLabel={option => option?.label}
                                    placeholder="Payment Mode"
                                    styles={{
                                        control: base => ({
                                            border: 0,
                                            display: 'flex',
                                            paddingTop: '20px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused
                                                ? 'rgb(222, 235, 255)'
                                                : state.isSelected
                                                ? '#FFF'
                                                : 'inherit',
                                            color: '#212529',
                                            '&:hover': {
                                                backgroundColor:
                                                    state.isSelected
                                                        ? '#DEEBFF'
                                                        : 'rgb(222, 235, 255)'
                                            }
                                        })
                                    }}
                                    // menuIsOpen={true}
                                    isClearable
                                />
                                <label for="floatingSelect">Payment Mode</label>
                                {isFieldErrorVisible &&
                                    !paymentDetails?.[
                                        PAYMENT_DETAILS_FIELDS.PAYMENT_MODE
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please choose Payment Mode.
                                        </div>
                                    )}
                            </div>
                        </form>
                        // </div>
                    )}
                </div>
            </SideDrawer>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProductReceivingAlertAction: payload =>
            dispatch(addProductReceivingAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductReceiving)
