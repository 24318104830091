import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import './ManageInventory.scss'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import LocationIcon from '../../assets/LocationIcon'
import { formatDate } from '../../common/utils/appUtils'
import { PAYMENT_MODE_OPTIONS_PRODUCT_RECEIVING } from '../../common/constant/page-constant'

function ViewInventory({ open, handleDrawerToggle, invoiceData }) {
    const navigate = useNavigate()

    return (
        <SideDrawer
            handleDrawerToggle={handleDrawerToggle}
            open={open}
            drawerHeading="Manage Inventory">
            {invoiceData && (
                <div className="viewProductWrap">
                    <div className="viewProductHeader">
                        <div className="headerRow dFlex jcSpaceBetween aiCenter">
                            <div className="headerRowLeft">{`Received by : ${invoiceData?.created_by}`}</div>
                            <div className="headerRowRight">
                                {invoiceData?.date_of_bill}
                            </div>
                        </div>
                        <div className="ProductUniqueTag">
                            {invoiceData?.invoice_number}
                        </div>
                        {/* <ul className='moreDetailsRow dFlex'>
                        <li className='moreDetails'>{`${invoiceData?.invoice_number}`} Batch No. : -----</li>
                        <li className='moreDetails'>Expiry Date: -----</li>
                    </ul> */}
                    </div>
                    <div className="viewProductHeader">
                        <div className="contactDetailsRow dFlex">
                            <div className="productSupplierName">
                                {invoiceData?.pharma_details?.name}
                                {invoiceData?.pharma_details?.is_approved ? (
                                    ''
                                ) : (
                                    <span className="text-danger">*</span>
                                )}
                            </div>
                            <div className="productSupplierName">
                                {invoiceData?.pharma_details?.mobile_no}
                            </div>
                        </div>
                        <div className="productSupplierAddress dFlex aiCenter">
                            <LocationIcon className="icon" />{' '}
                            {invoiceData?.pharma_details?.address}{' '}
                        </div>
                    </div>
                    <div className="productDescriptionWrap">
                        <table className="invoiceTable withScroll">
                            <thead>
                                <tr>
                                    <td>Sl. No.</td>
                                    <td width="20%">Product Name</td>
                                    <td>Qty</td>
                                    <td>Bonus Qty</td>
                                    <td>Batch No.</td>
                                    <td>Expiry Dt.</td>
                                    <td>Cost/Ut</td>
                                    <td>Rate/Ut</td>
                                    <td>Amt</td>
                                    <td>VAT %</td>

                                    <td>Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceData?.product_details?.map(
                                    (product, index) => {
                                        const amount =
                                            product.cost_per_unit *
                                            product.quantity

                                        const total = parseFloat(
                                            amount +
                                                (amount * product.vat) / 100
                                        ).toFixed(2)

                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td className="tdWrap">
                                                    {product.product_name}{' '}
                                                    {product?.dose}{' '}
                                                    {product.is_approved
                                                        ? ''
                                                        : '*'}
                                                </td>
                                                <td>
                                                    {product?.quantity || 0}
                                                </td>
                                                <td>
                                                    {product?.bonus_quantity ||
                                                        0}
                                                </td>

                                                {/* <div className="productDescription">
                                                    <div className="productTitle"></div>
                                                    {!product.is_approved && (
                                                        <div>
                                                            Approval pending
                                                            from DG E-Pharma
                                                        </div>
                                                    )}

                                                    <ul className="productQuantityList dFlex">
                                                        <li className="productQuantity">{`Batch No. : ${product.batch_number}`}</li>
                                                        <li className="productQuantity">{`Expiry Date: ${formatDate(
                                                            product.expiry_date
                                                        )}`}</li>
                                                    </ul>
                                                </div> */}
                                                <td>
                                                    {product?.batch_number ||
                                                        '-----'}
                                                </td>
                                                <td>
                                                    {formatDate(
                                                        product?.expiry_date ||
                                                            '-----'
                                                    )}
                                                </td>
                                                <td>
                                                    {product?.cost_per_unit ||
                                                        0}
                                                </td>
                                                <td>
                                                    {product?.rate_per_unit ||
                                                        0}
                                                </td>
                                                <td>
                                                    {parseFloat(amount).toFixed(
                                                        2
                                                    ) || 0}
                                                </td>
                                                <td>{product?.vat || 0}</td>

                                                <td>{total || 0}</td>

                                                {/* <tr>
                                                    <td>New Cost/Unit (BDT)</td>
                                                    <td align="right">{`${product.new_cost_per_unit}`}</td>
                                                </tr> */}
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </table>

                        <table
                            className="table productPrice"
                            cellPadding={2}
                            cellSpacing={2}>
                            <thead>
                                <tr>
                                    <td colSpan="2">Details</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Amount (BDT)</td>
                                    <td align="right">{invoiceData.amount}</td>
                                </tr>
                                <tr>
                                    <td>VAT (BDT)</td>
                                    <td align="right">{`${invoiceData.vat}`}</td>
                                </tr>
                                {/* <tr>
                                    <td>
                                        Discount (
                                        {invoiceData?.special_discount || 0}%)
                                    </td>
                                    <td align="right">
                                        {(invoiceData?.special_discount / 100) *
                                            invoiceData?.amount || 0}
                                    </td>
                                </tr> */}
                                <tr>
                                    <td>
                                        Trade Discount (
                                        {invoiceData?.trade_discount_percentage ||
                                            0}
                                        %)
                                    </td>
                                    <td align="right">
                                        {invoiceData?.trade_discount_amount ||
                                            0}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="strong">Total (BDT)</td>
                                    <td className="strong" align="right">
                                        {parseFloat(
                                            invoiceData.total_amount
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {invoiceData.payment_mode && (
                            <div className="disclaimerNotes">
                                <div className="disclaimerNotesHeading">
                                    Payment Mode
                                </div>
                                <div className="disclaimerNotesDesc">
                                    {PAYMENT_MODE_OPTIONS_PRODUCT_RECEIVING.find(
                                        item =>
                                            item.name ===
                                            invoiceData.payment_mode
                                    )?.label || invoiceData?.payment_mode}
                                </div>
                            </div>
                        )}
                        <div className="disclaimerNotes">
                            <div className="disclaimerNotesHeading">Notes</div>
                            <div className="disclaimerNotesDesc">
                                {invoiceData?.notes}
                            </div>
                        </div>
                        {invoiceData.product_details.some(
                            product => !product.is_approved
                        ) && (
                            <div className="disclaimerNotesDesc ">
                                * marked products require approval from DG
                                HealthCare
                            </div>
                        )}
                        {!invoiceData.pharma_details.is_approved && (
                            <div className="disclaimerNotesDesc ">
                                Supplier approval pending from DG HealthCare
                            </div>
                        )}
                    </div>
                </div>
            )}
        </SideDrawer>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

ViewInventory.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    invoiceData: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInventory)
